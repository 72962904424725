import { React, useEffect, useContext } from 'react';
import { getEventLedger } from '../../../../util/rex';
import Moment from 'moment';

import { useParams  } from "react-router-dom";
 
import { AppStateContext } from '../../../../AppState';
const Match = () => {
    const { state, updateState } = useContext(AppStateContext);
     const { eid } = useParams()  
    useEffect(() => {
        const fetchData = async () => {
      const result  = await getEventLedger(eid);
 
      updateState({ Teams_Length:result.teams.length });  
      updateState({ Runners_Length:result.runners.length });  
       
      updateState({ Ledger_Teams:result.teams });  
      updateState({ Ledger_Runners:result.runners });  
      updateState({ match_P_M:result.match_P_M });  
          updateState({ fancy_P_M:result.fancy_P_M });  
          
          updateState({ commission:result.commission });   
          updateState({ net_P_M:result.net_P_M });   

        };

        fetchData();
    }, []);

    return (
        <div className="main-content">
        <div className="main-inner">
        <section className="match-content">
   <div className="table_tittle">
      MATCH BETS
      <div className="pull-right"><button className="btn btn-xs btn-primary pull-right"  onClick={()=>window.history.back()}>Back</button></div>
   </div>
   <div className="table-responsive sports-tabel">
   {state.Teams_Length>0?
   <table className="table table-bordered table-hover">
         <thead>
            <tr className="headings">
               <th>TEAM</th>
               <th>Won By </th>
               <th>DATE</th>
               <th>RATE</th>
               <th>AMOUNT</th>
               <th>MODE</th>
               <th>P&amp;L</th>
            </tr>
         </thead>
         <tbody>
         {state.Ledger_Teams ?  state.Ledger_Teams.map((item, index) => (
         <tr>
               <td style={{"text-align": "left"}}>{item.Player_Name}</td>
               <td style={{"text-align": "left"}}>{item.Selection_Name}</td>
               <td style={{"text-align": "left"}}>{Moment(item.Updation_Datetime).format('DD MMM YY hh:mm A') } </td>
               <td>{item.Bet_Value}</td>
               <td>{item.Stake}</td>
               <td>{item.Bet_Type}</td>
               <td >{item.Result=="LOSS"?<span className="red">{'-'+item.Loss}</span>:<span className="green">{item.Profit}</span>}</td>
            </tr>
              )) : null}  
         </tbody>
      </table>:null}   
{state.Runners_Length>0?
      <table className="table table-bordered table-hover">
         <thead>
            <tr className="headings">
               <th>RUNNER</th>
               <th>DATE</th>
               <th>RATE</th>
               <th>RESULT</th>
               <th>AMOUNT</th>
               <th>MODE</th>
               <th>P&amp;L</th>
            </tr>
         </thead>
         <tbody>
         {state.Ledger_Runners ?  state.Ledger_Runners.map((item, index) => (
           
             <tr>  <td className=" " style={{"text-align": "left"}}>{item.Player_Name}</td>
               <td className=" " style={{"text-align": "left"}}>{Moment(item.Updation_Datetime).format('DD MMM YY hh:mm A') } </td>
               <td className=" ">{item.Bet_Value}</td>
               <td className=" ">{item.Gem_Result}</td>
               <td className=" ">{item.Stake}</td>
               <td className=" ">{item.Bet_Type}</td>
               <td >{item.Result=="LOSS"?<span className="red">{'-'+item.Loss}</span>:<span className="green">{item.Profit}</span>}</td>
      
            </tr>
            
          
              )) : null}   
         </tbody>
      </table>
      :null}
   </div>
   <div>
      <div className="ledger-header">Match Plus Minus</div>
    
{state.match_P_M>0?

<div className="green ledger-body">You Won {state.match_P_M}/- Coins.</div>
:  <div className="red ledger-body">You Lost {state.match_P_M}/- Coins.</div>
}

   </div>
   <div>
      <div className="ledger-header">Fancy Plus Minus</div>
{state.fancy_P_M>0?

      <div className="green ledger-body">You Won {state.fancy_P_M}/- Coins.</div>
:  <div className="red ledger-body">You Lost {state.fancy_P_M}/- Coins.</div>
}

   </div>
   <div>
      <div className="ledger-header">Total Commission</div>
      <div className="green ledger-body">You Won {state.commission}/- Coins.</div>
   </div>
   <div>
      <div className="ledger-header">Mob. App. Charges</div>
      <div className="red ledger-body">You Lost 0/- Coins.</div>
   </div>
   <div>
      <div className="ledger-header">Net Plus Minus</div>
      {state.net_P_M>0?

<div className="green ledger-body">You Won {state.net_P_M}/- Coins.</div>
:  <div className="red ledger-body">You Lost {state.net_P_M}/- Coins.</div>
}
    
   </div>
</section>
        </div>
     </div>
    );
};

export default Match;