import {React,useState,useEffect,useContext } from 'react';
import { useParams  } from 'react-router-dom';  
import { getBetsOdds } from '../util/rex'; 
import { FindJson } from '../util/jsonManeger';
 
import { AppStateContext }   from '../AppState';

const OddsBet = (props) => { 
  const { state, updateState } = useContext(AppStateContext);
  const [Runners, setRunners] = useState({Balance:0.00});

 
    
  const  {eid}  =   useParams()
  
  useEffect(() => {
    const fetchData = async () => {
     const _Runners=await getBetsOdds(eid);
        if (_Runners.length>0){ 
      setRunners(   FindJson(_Runners,'Selection_ID',props.Selection));   
    }
    };
    
    fetchData();
  }, [updateState]);  

  return (
    <>
    {
      Runners.Balance>0?<div  style={{ "color": "rgb(81, 153, 230)" }}>{Runners.Balance?Runners.Balance.toFixed(2):'0.00' }</div>:
    
    <div style={Runners.Balance?{ "color": "red" }:{ "color": "rgb(81, 153, 230)" }}>{Runners.Balance?Runners.Balance.toFixed(2):'0.00' }</div>
 


    }
    
</>

  );
};

export default OddsBet;