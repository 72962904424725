import React, { useState } from 'react';

const TextInput = (props) => {
    const [value, setValue] = useState(props.value);

  const handleChange = (event) => { 
    setValue(event.target.value);
  };

  return (
    <input  disabled={props.disabled} pattern={props.pattern} step={props.step} type={props.type} name={props.name} className={props.className} id={props.id}  value={value} onChange={handleChange}/>
        
  );
}; 

export default TextInput;