import { React, useEffect, useContext } from 'react';
import { getCompletedBets } from '../../../util/rex';
import { useParams  } from "react-router-dom";
import Moment from 'moment';

import { AppStateContext } from '../../../AppState';
   let Total = 0;
         const Completed = () => {
    const { state, updateState } = useContext(AppStateContext); 

    const { eid } = useParams() 

    useEffect(() => {
        const fetchData = async () => {
 await getCompletedBets(eid).then((resp)=>{
 if (resp && resp.length > 0) {
    Total = resp.reduce((_Total, item) => _Total + item.PL, 0);
  }

 updateState({ Completed_Bets:resp });  


 })
           
          
             
        };

        fetchData();
    }, []);

    return (
        <div className="main-content">
        <div className="main-inner">
        <section className="match-content">
           <div className="table_tittle">
              Completed Bet History
              <div className="pull-right"><button onClick={() => history.back()} className="btn btn-xs btn-primary pull-right">Back</button> </div>
           </div>
           <div className="table-responsive">
              <table className="table table-bordered table-hover">
                 <thead>
                    <tr className="headings">
                       <th>S.No. </th>
                       <th>RUNNER </th>
                       <th>DATE </th>
                       <th>RUN </th>
                       <th>RATE </th>
                       <th>RESULT </th>
                       <th>AMOUNT </th>
                       <th>MODE </th>
                       <th>P&amp;L </th>
                    </tr>
                 </thead>
                 <tbody>
                 {state.Completed_Bets ? state.Completed_Bets.map((item, index ) =>  (             <>
                    <tr className="heading_user_table">
                       <td>{index+1}</td>
                       <td>{item.RUNNER}</td>
                       <td>{Moment(item.DATE_TIME).format('DD MMM YY hh:mm A')  }</td>
                       <td>{item.RUN}</td>
                       <td>{item.RATE}</td>
                       <td>{item.RESULT}</td>
                       <td>{item.AMOUNT}</td>
                     <td>{item.MODE}</td>
                       <td><span className={item.PL>0?'green':'red'}>{item.PL}</span></td>
                      
                    </tr>
                     </> 
    ) ): 
                    <tr><th colspan="9">No record found</th></tr>}  <tr>
                       <td colspan="8">Total Plus Minus</td>
                       <td><span className={Total>0?'green':'red'}>{Total} </span></td>
                    </tr> 
                  
                 </tbody>
              </table>
           </div>
        </section>
        </div>
        </div>
    );
};

export default Completed;