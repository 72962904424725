  
import {React,useContext,useState} from "react";  
  
import { Route, Switch } from "react-router-dom";
import ChipsModel from '../../../components/ChipsModel'; 
import { AppStateContext } from '../../../AppState';
  
import  MyBets   from '../../../components/MyBets';
 
import {useRouteMatch} from "react-router-dom";

function Header() {  
  
  const match = useRouteMatch();
   
  const { state, updateState } = useContext(AppStateContext);

  const [Tab, setTab] = useState("All");
  const handleChange = (type) => { 
  
   setTab(type);  
       
     
  }; 
  const authUser =  state.authUser; 
     return (
    <header>
    <div className="header-inner w-100">
      {/* <div>
        <input type="checkbox" id="drawer-toggle" name="drawer-toggle"/><label className="button" for="drawer-toggle"
          id="drawer-toggle-label"></label>
        <nav id="drawer">
          <div style={{"padding-bottom": "40px"}}><label className="button" for="drawer-toggle"
              id="drawer-toggle-close"></label></div>
          <ul>
            <li><a href="/account/ac-statement">Account Statement </a></li>
            <li><a href="/account/my-ledger">My Ledger </a></li>
            <li>   <ChipsModel ButtonType='link' /></li>
          </ul>
        </nav>
      </div> */}
      <input type="checkbox" id="drawer-toggle-right" name="drawer-toggle-right"/><label className="button"
        for="drawer-toggle-right" id="drawer-toggle-label-right"></label>
      <nav id="drawer-right"><label className="button" for="drawer-toggle-right" id="drawer-toggle-close-right"></label>
          {/*<div>
        <div className="mod-header">
      
    <div className="select-tv-ico">
        <span>BetSlip</span>   <ChipsModel ButtonType='button' />
   </div>
   </div>
   </div> */}
         <div className="right-bet-inner">
                <div
                  className="mod-header tab_bets betsheading"
                  style={{ display: "block" }}
                >
                  <ul className="nav nav-pills mb-1" id="pills-tab" role="tablist">
                    <li className="nav-item betdata active">
                      <a className="allbet" onClick={()=>{handleChange("All")}}>
                        <span className="bet-label">All Bet </span>
                        <span className="bat_counter"></span>
                      </a>
                    </li>
                    <li className="nav-item betdata">
                      <a className="matchbet" onClick={()=>{handleChange("Odds")}}>
                        <span className="bet-label">Match Bet</span>
                        <span className="bat_counter"></span>{" "}
                      </a>
                    </li>
                    <li className="nav-item betdata">
                      <a className="fancybet" onClick={()=>{handleChange("Session")}}>
                        <span className="bet-label">Fancy Bet</span>
                        <span className="bat_counter"></span>{" "}
                      </a>
                    </li>
                    <li className="nav-item betdata">
                      <a className="casinobet" onClick={()=>{handleChange("Casino")}}>
                        <span className="bet-label blinking">Casino Bet</span>
                        <span className="bat_counter"></span>{" "}
                      </a>
                    </li>
                    <li className="nav-item betdata">
                      <a className="matkabet" onClick={()=>{handleChange("Matka")}}>
                        <span className="bet-label blinking">Matka Bet</span>
                        <span className="bat_counter"></span>{" "}
                      </a>
                    </li>
                    <li className="nav-item full-screen"></li>
                  </ul>
                </div>
                <div id="MatchUnMatchBetaData">
                  <div id="accountView" role="main">
                    <span id="msg_error"></span>
                    <span id="errmsg"></span>
                    <div className="balance-panel-body">
                      <div className="table-responsive sports-tabel">
                      <Switch>
                          <Route
                            path={`/dashboard/events/:eid`}
                            render={() => <MyBets type={Tab} />}
                          />
                          <Route path={`/`}  render={() => <MyBets type={Tab} />} />
                        </Switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      </nav>
      <div className="logo"><a className="site_title endcooki" href="/" style={{"text-decoration": "none"}}><img
            src="https://urbet.in/static/media/biharlogo.49d3c75d.png" alt="WBT99" style={{"width": "40%"}}/></a></div>
      <div className="mike">
        <marquee direction="left" scrollamount="7"><i className="fa fa-bullhorn" aria-hidden="true"></i> Betting started on exchange and Casino is coming soon !
        </marquee>
      </div>
      <div className="balancewallet"><a className="Wallet" href="/dashboard">Chips:  {authUser ?  parseFloat( authUser.Chips).toFixed(2) : "Loading"}</a><a className="hidden-xs UserLiability"
          href="/dashboard">Expo : {authUser ?  parseFloat( authUser.Liability).toFixed(2) : "Loading"}</a> </div>
      <div className="right_user-menu">
        <div className="dropdown"><span className=" dropbtn dropdown-toggle" type="button" data-toggle="dropdown">   {authUser ? "C"+ authUser.ID +" ("+ authUser.Name+")" : "Loading"}</span>
          <ul className="dropdown-content ">
            <li className="label-user"><a href="/dashboard/change_password"><i className="fas fa-unlock-alt"></i> Change
                Password</a> </li>
            <li className="label-user"><a className="endcooki" onClick={()=>{ localStorage.removeItem("token");
    localStorage.removeItem("authUser"); localStorage.removeItem("Login"); 
    location.reload();
   }}><i className="fas fa-sign-out-alt"></i> Log Out</a> </li>
          </ul>
        </div>
        <div className="mobile-belance"><span><a href="#" className="Wallet">Chips: {authUser ? parseFloat( authUser.Chips ).toFixed(2) : "Loading"}</a></span><span><a href="#"
              className="UserLiability"> Expo : {authUser ?  parseFloat( authUser.Liability ).toFixed(2) : "Loading"}</a></span></div>
      </div><span className="bettoggle"> Bets </span>
    </div>
  </header>);
}

export default Header;
