import React, { createContext, useState } from 'react';

// Create the app state context
export const AppStateContext = createContext();

// Create the AppStateProvider component
export const AppStateProvider = ({ children }) => {
  const [state, setState] = useState({
    property: 'initial value',
  });

  // Define a function to update the state
  const updateState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  return (
    <AppStateContext.Provider value={{ state, updateState }}>
      {children}
    </AppStateContext.Provider>
  );
};