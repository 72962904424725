import React from 'react'; 

import { OverlayTrigger, Popover } from 'react-bootstrap';
const _Popover = () => {
  const popover = (

<div className="popover show bs-popover-auto" x-placement="bottom" style={{"position": "absolute", "will-change": "transform", "top": "0px", "left": "0px", "transform": "translate3d(1191px, 268px, 0px)"}}>
  <div className="popover-inner" role="tooltip">
    <div className="popover-body"><b>Min: 100<br/>Max: 50000</b>
    </div>
    </div>
    <span className="arrow" style={{"left": "38px"}}>

    </span>
    </div>
 
  );

  return (
        <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
      <span  style={{ "float": "right", "margin-right": "10px" }}>
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABMlBMVEUAAAAAgJIJfo8If5EIgJAIgJAIgJAIgJAJgJEAdokHgI0If5EIf5AIgJAHfJEAcY4HgJAIf5AHgJAAgJ8If5AIf5AKeo8KfZIIf5AGgJALgJUIf5AIfpEKfZEJfpAIgJAAbZIIgJAHfo8If5AIf5AAgIAFfZIIf5AHfo4JgJEJgJEIgJAIfpEKgI8Hf5AAgJUJfpAIgJAIgJAIf48If5AHgJALepAOgI4IgJAIgJAIf5AJgJEIfpAHgJEIgJAIf5AIf5AIfpAAgI8If5EIf5AJgJEMeZIHf48IgJAIgI8If5AIgJAAgIAJfpAIgJAGgJEHf5EIgJAIgI8IgI8IgJAAgIAIf5AIgJAIfY4IgJEIf5AIf5AIgJAIf5AJe44GfY4If5AIgJAIf5AJgJEIf5AAAADnHCSFAAAAZHRSTlMADlmbyur6mlgNJp31nCUJjPuKCNPHGTHjLhjnXzNx9Ac+aYH9Bi/zTXiW8n1QjwxX2PiZ1WwXEqbI63ZjSO7p+f4Qyd/SFYtcRKOYAlW4LI324CLcBIfUPYbhxeLlGy3BxpdWz+XyAwAAAAFiS0dEAIgFHUgAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQflAhMFDybhb9BZAAABZElEQVQ4y32T2VrCQAyFI5QCsgqiUNSioCgqILiBaIsLLogbbijuff9nMK06TaCfuTs5/0zSaQIgYsTlljyy7JG8Pj8Mx2ggaIgIhsIDdiQ6ZrCQY3Hqh8eNoUhM2P6kx3CIZEqcd/SRUH7r0/vTU9MzqqiSsYAoPTWLiTmhstb30f5z85hZEDJvFgmwuouYWbJlAd8vyIDlldUiuVItgcv4N8rgZjq9VuFAFSRbrG9sAmxtM6AGdVvsNHaxxfgeBTTQqWzuI3FAMxUO5CIIHHKgTuUR+q0mLyFReYzACWsyAV4qTxE4Y0AbfESp+PfONQZ0wE+e+gIvuGQ+PjWEbHmFQIcBXnOeZCGL5vjlr29EQu+aAxET+tbahzu7zXtrouIJkXhoPHZ6T0I+/4wcKH3DMV5e/8Y6lXTy+2/2YrTeh/0Pha5WJqtyW+9lBrZTKRBErXYd9rtUbtc0Xddqn18lO/sNxUwSHR4NB6YAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDItMTlUMDU6MTU6MzgrMDA6MDCyNS9NAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTAyLTE5VDA1OjE1OjM4KzAwOjAww2iX8QAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=" style={{ "width": "15px" }} />
            </span>
      </OverlayTrigger>
    
  );
};

export default _Popover;