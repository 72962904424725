import {React,useState,useContext, useEffect} from 'react'; 
import { AppStateContext } from '../../../../AppState';  
import { getCircles } from '../../../../util/rex'; 
export const   Live_Casino = (props) => { 
  const [isLoading, setIsLoading] = useState(true);
  const [Circles, setCircles] = useState([]);
  
  const { state, updateState } = useContext(AppStateContext);
 
      

  useEffect(() => {
    const fetchData = async () => {
      await getCircles().then((circles)=>{


        setCircles( circles);    

 


          })
    setIsLoading(false);
     

      // Perform any necessary actions after getUser() is complete
      // For example, update state or perform other logic
    };

    fetchData();
  }, []); 
 

 const ShowCircles =()=>{ 
     var _Circles= null;
    
    
     _Circles=  Object.keys(Circles).map((innerAttr, index) => {
    return (
    
     <div class="text-center padding-lr-0 col-6 col-md-2">
      <a href={"/dashboard/livegames/"+Circles[innerAttr].Code}>
        <img src={Circles[innerAttr].Image} class="casino-img-home" alt=""/>
          <div class="game-text">{Circles[innerAttr].Title}</div>
          </a>
          </div>
          
          
  
  
      )
        
      
    })

 
return (_Circles)


 }

  
  return (  
  
    <> 
 <div class="live-games-heading-block"><span class="live-games-heading-txt">Live Casino</span></div>

 <div className="match-tabs">
  <div class="padding-lr-15 row">
  {ShowCircles()}


                                           
          <div class="text-center padding-lr-0 col-6 col-md-2">
            <a href="#/">
              <img src="https://qph.cf2.quoracdn.net/main-qimg-03206cac563abf40b8f7edbbc94939eb" class="casino-img-home" alt="" />
              <div class="game-text">Aviator <em  style={{"color": "#eb2e72"}}>(Coming Soon)</em></div>
            </a>
          </div>

{/*   
          <div class="text-center padding-lr-0 col-6 col-md-2">
            <a href="#/" class="casino-list">
              <img src="https://ik.imagekit.io/bmaxmbpyx/https://newdiamond63.com/assets/images/casino-icons/-1027.webp" class="casino-img-home-upcoming" style={{"object-fit": "fill!important"}} alt="" />
              <div class="game-text">Baccarat</div>
            </a>
          </div>

          <div class="text-center padding-lr-0 col-6 col-md-2">
            <a href="#/" class="casino-list">
              <img src="https://ik.imagekit.io/bmaxmbpyx/https://newdiamond63.com/assets/images/casino-icons/-1028.webp" class="casino-img-home-upcoming" style={{"object-fit": "fill!important"}} alt="" />
              <div class="game-text">Baccarat2</div>
            </a>
          </div>

          <div class="text-center padding-lr-0 col-6 col-md-2">
            <a href="#/" class="casino-list">
              <img src="https://ik.imagekit.io/bmaxmbpyx/https://newdiamond63.com/assets/images/casino-icons/-1019.webp" class="casino-img-home-upcoming" style={{"object-fit": "fill!important"}} alt="" />
              <div class="game-text">Race 20-20</div>
            </a>
          </div>

          <div class="text-center padding-lr-0 col-6 col-md-2">
            <a href="#/" class="casino-list">
              <img src="https://ik.imagekit.io/bmaxmbpyx/https://newdiamond63.com/assets/images/casino-icons/-1015.webp" class="casino-img-home-upcoming" style={{"object-fit": "fill!important"}} alt="" />
              <div class="game-text">Bollywood Casino</div>
            </a>
          </div>

          <div class="text-center padding-lr-0 col-6 col-md-2">
            <a href="#/" class="casino-list">
              <img src="https://ik.imagekit.io/bmaxmbpyx/https://newdiamond63.com/assets/images/casino-icons/-1012.webp" class="casino-img-home-upcoming" style={{"object-fit": "fill!important"}} alt="" />
              <div class="game-text">32 Cards A</div>
            </a>
          </div>

          <div class="text-center padding-lr-0 col-6 col-md-2">
            <a href="#/" class="casino-list">
              <img src="https://ik.imagekit.io/bmaxmbpyx/https://newdiamond63.com/assets/images/casino-icons/-1010.webp" class="casino-img-home-upcoming" style={{"object-fit": "fill!important"}} alt="" />
              <div class="game-text">32 Cards B</div>
            </a>
          </div>

          <div class="text-center padding-lr-0 col-6 col-md-2">
            <a href="#/" class="casino-list">
              <img src="https://ik.imagekit.io/bmaxmbpyx/https://newdiamond63.com/assets/images/casino-icons/-5.webp" class="casino-img-home-upcoming" style={{"object-fit": "fill!important"}} alt="" />
              <div class="game-text">Poker One Day</div>
            </a>
          </div>

          <div class="text-center padding-lr-0 col-6 col-md-2">
            <a href="#/" class="casino-list">
              <img src="https://ik.imagekit.io/bmaxmbpyx/https://newdiamond63.com/assets/images/casino-icons/-6.webp" class="casino-img-home-upcoming" style={{"object-fit": "fill!important"}} alt="" />
              <div class="game-text">Poker 6 Players</div>
            </a>
          </div>
          <div class="text-center padding-lr-0 col-6 col-md-2">
            <a href="#/" class="casino-list">
              <img src="https://ik.imagekit.io/bmaxmbpyx/https://newdiamond63.com/assets/images/casino-icons/-4.webp" class="casino-img-home-upcoming" style={{"object-fit": "fill!important"}} alt="" />
              <div class="game-text">Poker 20-20</div>
            </a>
          </div>
          <div class="text-center padding-lr-0 col-6 col-md-2">
            <a href="#/" class="casino-list">
              <img src="https://ik.imagekit.io/bmaxmbpyx/https://newdiamond63.com/assets/images/casino-icons/-1029.webp" class="casino-img-home-upcoming" style={{"object-fit": "fill!important"}} alt="" />
              <div class="game-text">Casino War</div>
            </a>
          </div>
          <div class="text-center padding-lr-0 col-6 col-md-2">
            <a href="#/" class="casino-list">
              <img src="https://ik.imagekit.io/bmaxmbpyx/https://newdiamond63.com/assets/images/casino-icons/-3.webp" class="casino-img-home-upcoming" style={{"object-fit": "fill!important"}} alt="" />
              <div class="game-text">Teenpatti Test</div>
            </a>
          </div>
          <div class="text-center padding-lr-0 col-6 col-md-2">
            <a href="#/" class="casino-list">
              <img src="https://ik.imagekit.io/bmaxmbpyx/https://d2472.com/assets/images/casino-icons/-1018.webp" class="casino-img-home-upcoming" style={{"object-fit": "fill!important"}} alt="" />
              <div class="game-text">5 Cricket</div>
            </a>
          </div>

     */}    
                                              </div>
  
  </div> </>
  
     );
};
  export default Live_Casino