import {React,useState} from 'react'; 
import {getAuth} from './Auth'; 
const SignIn=()=> {


    const [IsLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({name: '',password: ''});
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };
      const handleSubmit = (e) => {
        e.preventDefault();
      
    setIsLoading(true);
        getAuth(formData).then(ress=>{
    

            
    setIsLoading(false);
      }).catch(function (error) {
        
    setIsLoading(false);
    
      }); 

    }


   return (
    <div className="login_container">
   <div className="login_wrapper-bg">
      <div className="lazy-container-login" id="wrapper">
         <div style={{"text-align": "center"}}>
            <div style={{"color": "rgb(255, 255, 255)", "font-size": "18px", "font-weight": "bold"}}><img src="https://urbet.in/assets/images/biharlogo.png" alt="..."/></div>
         </div>
         <div id="login" className="form">
            <section className="login_content">
               <form className="m-0"  onSubmit={handleSubmit}>
                  <input type="hidden" name="compute" value="013177c69564abc2bd92a4dd77c4c554"/>
                  <div className="input_box"><i className="fas fa-user"></i><input type="text" name="name" placeholder="Username"  onChange={handleChange} required/></div>
                  <div className="input_box"><i className="fas fa-lock"></i><input type="password" onChange={handleChange}  name="password" placeholder="Password" required/></div>
                  <div className="clearfix"></div>
                  <div><button type="submit" className="btn btn-success my-2 submit">{IsLoading? 
                 <div role="status" class="  spinner-border text-light"><span class="sr-only">Loading...</span></div>
                :<> Log in <i className="fas fa-sign-in-alt"></i></>}</button></div>
               </form>
            </section>
         </div>
      </div>
   </div>
</div>
  );
}

export default SignIn;
