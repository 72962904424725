import React  from 'react'; 
 
import {  message } from 'antd';

import axios from './Api' 
message.config({
  top: 40,
    duration: 3,
  maxCount: 3,  style: {
    zIndex: '9999', 
  } 
});
function calculatePercentage(value, percentage) {
  if (isNaN(value) || isNaN(percentage)) {
    return "Invalid input. Please provide valid numbers.";
  }

  const result = (percentage / 100) * value;
  return result;
} 
export const getBets = async(event) => {
  var url = '/info/bets/all'  ;
  ;
  if(event!=undefined) {
    url='/info/bets/'+event ;
  } else{

     
  }
  try { 
       const response = await axios.get(url);

const data = await response.data; 
 
if (data.success===true){ 

   
return   data.bets  
 
} 
  
}
catch (e) {  
    
  message.error(e.message);  
  return  {login:false } 
} 
};

export const getBetsOdds = async(event) => {
  var url =  ''  ;
  ;
  if(event!=undefined) {
    url='/info/bets/odds/'+event ;
  } else{

     
  } 
  try { 
       const response = await axios.get(url);

const data = await response.data; 
 
if (data.success===true){ 
   
return   data.Runners  
 
} 
  
}
catch (e) {  
    
  message.error(e.message);  
  return  {login:false } 
} 
};
export const createBet = async (BetData) => {
  try {   
     //  const authUser=JSON.parse(localStorage.getItem("authUser"));


     if ( BetData.Block_Betting=="False"){
      if ( BetData.Sport=="Cricket"){

    if ( BetData.GType=="session"){

  
     if (BetData.Chips>=BetData.Stake ){

    //  await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('/bet/session/create',BetData);
   const data = await response.data;

   if (data.success===true){

    
   message.success(data.message); 
 
   }else{

    
    message.error(data.message); 

   }
      return   await data ;
  
  }else  {
  message.error("Not have enough Chips"); 


}

}
else  if ( BetData.GType=="odds") 
{
  
  const response = await axios.post('/bet/odds/create',BetData);
  const data = await response.data;

  if (data.success===true){

   
  message.success(data.message); 

  }else{

   
   message.error(data.message); 

  }
  //alert(JSON.stringify(data.users))
    return   await data ;

  
}

}
else  if ( BetData.Sport=="Casino") 
  {
     const response = await axios.post('/bet/casino/create',BetData);
    const data = await response.data;
      
    if (data.success===true){
  
   
    message.success(data.message); 
  
    }else{
  
     
     message.error(data.message); 
  
    }
       return   await data ;
  
    
  }
  

 }else{

  message.error('You are not allowed to place bet');  
 }
 }


  
  
  catch (e) {  
    message.error(e.message);  
  }
}
 
   
export const SetChipsStake = async (BetData) => {
  try { 
 
 
    const response = await axios.post('/settings/set/chips',BetData);
   const data = await response.data;

   if (data.success===true){

    
   message.success(data.message); 
 
   }else{

    
    message.error(data.message); 

   }
     return   await data ;
  
  }
  catch (e) {  
    message.error(e.message);  
  }
}
 
export const GetChipsStake = async (BetData) => {
  try { 
 
 
    const response = await axios.get('/settings/get/chips' );
   const data = await response.data;

    
     return  {Chips: await data.chips };
  
  }
  catch (e) {  
    message.error(e.message);  
  }
}

export const getStatement = async() => {
  
  try { 
       const response = await axios.post('/info/statement' );
const data = await response.data; 
 
if (data.success===true){
 
return  data.transactions

}else{
 
  
if (data.verified==false){
   return  {login:false } 
   
}
} 
  
}
catch (e) {  
    
  message.error(e.message);   
} 
};

export const getEvents = async() => {
  
  try { 
       const response = await axios.post('/events' );
const data = await response.data; 
  
if (data.success===true){ 
return await data.events

}else{
 
  
if (data.verified==false){
   return  {login:false } 
   
}
} 
  
}
catch (e) {  
    
  message.error(e.message);   
} 
};
export const getCircles = async() => {
  
  try { 
       const response = await axios.post('/circles' );
const data = await response.data; 
  
if (data.success===true){ 
 
return await data.circles

}else{
 
  
if (data.verified==false){
   return  {login:false } 
   
}
} 
  
}
catch (e) {  
    
  message.error(e.message);   
} 
};
export const getLedger = async() => {
  
  try { 
       const response = await axios.post('/info/ledger' );
const data = await response.data; 
  
if (data.success==true){
 
 
return  await data.ledger;

}else{
 
  
if (data.verified==false){
  return  {login:false } 
  
} 
} 
  
}
catch (e) {  
    
  message.error(e.message);   
} 
};

export const getEventLedger = async(Event) => {
  
  try { 
       const response = await axios.post('/info/ledger/'+Event );
const data = await response.data; 
  
if (data.success==true){
  
return  await data;

}else{
 
  
if (data.verified==false){
  return  {login:false } 
  
} 
} 
  
}
catch (e) {  
    
  message.error(e.message);   
} 
};

export const getCompletedBets = async(Event) => {
  
  try { 
       const response = await axios.post('/info/completed/'+Event );
const data = await response.data; 
  
if (data.success==true){
   
return  await data.completed;

}else{
 
  
if (data.verified==false){
  return  {login:false } 
  
} 
} 
  
}
catch (e) {  
    
  message.error(e.message);   
} 
};