  
import   {React,useState,state, useEffect,useContext} from "react";  
import Cricket from './Cricket'; 
import Live_Casino from './Live-Casino'; 
import CrickOwlCarouselet from '../../../components/OwlCarousel';
import { AppStateContext } from "../../../AppState";
const Games = () => {   

  const { state } = useContext(AppStateContext); 
     
  //const [active, setActive] = useState("InPlay");

  const [active, setActive] = useState("ShowAll");

   
   return (
    <div id="UpCommingData" style={{"margin-bottom":"90px", "background": "rgb(255, 255, 255)"}}>
       
      

<CrickOwlCarouselet />



 
       <Cricket type={active} />
  
{/* {state.authUser.ID==7?   */}
   <Live_Casino type={active} />
   
   
   
   {/* :null} */}
      
   
</div>
  );
}

export default Games;
