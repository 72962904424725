  
import React from "react"; 
import { Link } from "react-router-dom"; 
 

function NavBar() {
   return (
    <><div className="mikemobile">
       <marquee direction="left" scrollamount="7"><i className="fa fa-bullhorn" aria-hidden="true"></i> Betting started on exchange and Casino is coming soon ! </marquee>
     </div><div className="menu">
         <nav id="cssmenu">
           <ul className="nav">
             <li><label className="button" for="drawer-toggle" id="drawer-toggle-close"></label></li>
             <li>{<Link to="/account/ac-statement">Account Statement </Link> } </li>
             <li>{<Link to="/account/my-ledger">My Ledger </Link> } </li>
             <li>{<Link to="/dashboard/livegames">Live Games </Link> } </li>
              
           </ul>
         </nav>
       </div><div className="header d-lg-none d-md-none">
         <div className="container">
           <nav className="bottom-nav">
             <div className="bottom-nav-item active"><a href="/dashboard">
               <div className="bottom-nav-link"><i className="fas fa-home"></i><span>Home</span></div>
             </a></div>
             <div className="bottom-nav-item"><a href="/account/my-ledger">
               <div className="bottom-nav-link"><i className="fas fa-thumbtack"></i><span>My Leadger</span></div>
             </a></div>
             <div className="bottom-nav-item"><a href="/dashboard/livegames">
               <div className="bottom-nav-link"><i className="fas fa-money-bill-alt"></i><span>Live Casino</span></div>
             </a></div>
             <div className="bottom-nav-item"><a href="/account/ac-statement">
               <div className="bottom-nav-link"><i className="fas fa-book"></i><span>Statement</span></div>
             </a></div>
             <div className="bottom-nav-item"><a href=""  onClick={()=>{ localStorage.removeItem("token");
    localStorage.removeItem("authUser"); localStorage.removeItem("Login"); 
    location.reload();
   }}>
               <div className="bottom-nav-link"><i className="fas fa-user-circle"></i><span>Logout</span></div>
             </a></div>
           </nav>
         </div>
       </div></>);
}

export default NavBar;
