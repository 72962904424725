import React, { useContext, useEffect,useState } from 'react';
import SignIn from "./Signin";
import Sections from "./Sections";
import { AppStateContext } from '../AppState';
import { getUser } from './Signin/Auth';

const App = () => {
  const { state, updateState } = useContext(AppStateContext);
 
  useEffect(() => {
    const fetchData = async () => {
      
     updateState( await getUser());
   
    };

    fetchData();
  }, []); 
  return (
    <>
{state.login==false?
 
<SignIn />
      
      :
  localStorage.getItem("Login") ? <Sections /> : <SignIn /> 
    

}

<div style={{ position: 'relative' }}>
      
      <div id="message-container" style={{ position: 'absolute', top: 0, zIndex: "9999!important" }}>
       
      </div>
    </div>
    </>
  );
}

export default App;
