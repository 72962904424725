import {React,useState,useContext, useEffect} from 'react';
import {io} from 'socket.io-client';
import { AppStateContext } from '../../../../AppState'; 

import Moment from 'moment';
export const   Cricket = (props) => { 
  const [isLoading, setIsLoading] = useState(true);
  
  const { state, updateState } = useContext(AppStateContext);

 
      

  useEffect(() => {
    const fetchData = async () => {
       
    setIsLoading(false);
     

      // Perform any necessary actions after getUser() is complete
      // For example, update state or perform other logic
    };

    fetchData();
  }, []); 
 



  function isTimeLessThanTwoHours(time) {
    // Get the current time
    const currentTime = new Date();
  
    // Calculate the time difference in milliseconds
    const timeDifference = time - currentTime;
  
    // Convert the time difference to hours
    const hoursDifference = timeDifference / (1000 * 60 * 60);
     // Check if the time difference is less than 2 hours
    return hoursDifference >= 0.30;
  }
const GetInplay =(time)=>{
let Inplay ;
const specificTime = new Date(time); // Replace with the specific time you want to compare

if (isTimeLessThanTwoHours(specificTime)) {
    Inplay= false;
           
            
              }else{
                Inplay=true;
              }


              return Inplay
}
 const ShowEvents =()=>{ 
     var Events= null;
    if(props.type==="InPlay"){
  
  Events=  Object.keys(state.E).map((innerAttr, index) => {
    if(GetInplay(state.E[innerAttr].Start_Time)){
 return (
        
        <tr title={state.E[innerAttr].Event_Name} style={{"padding": "5px"}}>



 
        <td style={{"margin-top": "5px"}}>
        <a className="event-name" href={"/dashboard/events/"+state.E[innerAttr].Event_Id}>{state.E[innerAttr].Event_Name}</a>
        <span class="notification">{state.E[innerAttr].Fancy?'F':null}</span>
        <span class="notification">{state.E[innerAttr].Bm?'M':null}</span>
        
        <div><small><time>{Moment(state.E[innerAttr].Start_Time).format('D MMM YYYY hh:mm A')  }</time><span>
        <img width="20" src="https://urbet.in/assets/images/live-tv.png"/>
        </span></small>
        
        {GetInplay(state.E[innerAttr].Start_Time)?<span className="in_play">Inplay</span>:null} </div></td>
        
        
        <td className="back betting-blue first-el" style={{"margin-bottom": "5px"}}>
        <strong className="odds ng-binding">-</strong></td><td className="lay betting-pink">
        <strong className="odds ng-binding">-</strong></td><td className="back betting-blue">
        <strong className="odds ng-binding">-</strong></td><td className="lay betting-pink">
        <strong className="odds ng-binding">-</strong></td><td className="back betting-blue">
        <strong className="odds ng-binding">-</strong></td><td className="lay betting-pink last-el">
        <strong className="odds ng-binding">-</strong></td></tr>






    )
      
    }
  })
   } else if(props.type==="ShowAll"){
  
 
    
    Events=  Object.keys(state.E).map((innerAttr, index) => {
    return (
          
          <tr title={state.E[innerAttr].Event_Name} style={{"padding": "5px"}}>
  
  
  
   
          <td style={{"margin-top": "5px"}}>
          <a className="event-name" href={"/dashboard/events/"+state.E[innerAttr].Event_Id}>{state.E[innerAttr].Event_Name}</a>
          {/* <span class="notification">{state.E[innerAttr].Fancy?'F':null}</span>
          <span class="notification">{state.E[innerAttr].Bm?'M':null}</span>
           */}
          <div><small><time>{Moment(state.E[innerAttr].Start_Time).format('D MMM YYYY hh:mm A')  }</time><span>
          <img width="20" src="https://urbet.in/assets/images/live-tv.png"/>
          </span></small>
          
          {GetInplay(state.E[innerAttr].Start_Time)?<span className="in_play">Inplay</span>:null} </div></td>
          
          
          <td className="back betting-blue first-el" style={{"margin-bottom": "5px"}}>
          <strong className="odds ng-binding">-</strong></td><td className="lay betting-pink">
          <strong className="odds ng-binding">-</strong></td><td className="back betting-blue">
          <strong className="odds ng-binding">-</strong></td><td className="lay betting-pink">
          <strong className="odds ng-binding">-</strong></td><td className="back betting-blue">
          <strong className="odds ng-binding">-</strong></td><td className="lay betting-pink last-el">
          <strong className="odds ng-binding">-</strong></td></tr>
  
  
  
  
  
  
      )
        
      
    })


   }






return (Events)


 }

  
  return (  
  
    <> 
 <div class="live-games-heading-block"><span class="live-games-heading-txt">Cricket</span></div>

 <div className="match-tabs">


 {/* <ul className="nav nav-tabs">
     <li className="active" onClick={() => setActive("InPlay")}>
<a data-toggle="tab" >
<span><img src="https://urexch.in/assets/images/play-button.png" className="icon-sport" style={{"margin": "0px 5px"}}/>InPlay</span></a></li>
     <li className="" onClick={() => setActive("ShowAll")}>
<a data-toggle="tab">
<span><img src="https://urexch.in/assets/images/cricket.png" className="icon-sport" style={{"margin": "0px 5px"}}/>Cricket</span></a></li>
     <li className="">
<a href="/dashboard/livegames">
<span><i className="fas fa-gamepad">
</i> Casino</span></a>
     </li>
     <li className="">
<a href="/dashboard/satta-events">
<span><i className="fas fa-gamepad">
</i> Matka</span></a>
     </li>
   </ul>  */}
   <div className="tab-content">
     <div className="tab-pane in active">
       <div className="tabel-scroll">
  
 
    {!isLoading ?  
    
    
    <>      
      
    {(state.E && state.E.length>0) ?  
        <table className="market-listing-table open_bets-table">
        <thead>
        {/* <tr>
        <th className="title">
    <i className="fa far fa-play-circle" style={{"margin": "0px 5px", "color": "green"}}>
    </i>Cricket</th>
    <th className="col-first-player  _align-center" colspan="2">1</th><th className="player-draw  _align-center ng-scope" colspan="2">X</th><th className="col-second-player  _align-center" colspan="2">2</th></tr>
     */}</thead><tbody>
     

     {ShowEvents()}
     
    
    
    </tbody>
    </table>
    : 
 null
    
    }
    
     </>
     
     :   <div className="text-center" style={{"padding": "25px"}}> 
     <div className="sk-circle">
   <div className="sk-circle1 sk-child"></div>
   <div className="sk-circle2 sk-child"></div>
   <div className="sk-circle3 sk-child"></div>
   <div className="sk-circle4 sk-child"></div>
   <div className="sk-circle5 sk-child"></div>
   <div className="sk-circle6 sk-child"></div>
   <div className="sk-circle7 sk-child"></div>
   <div className="sk-circle8 sk-child"></div>
   <div className="sk-circle9 sk-child"></div>
   <div className="sk-circle10 sk-child"></div>
   <div className="sk-circle11 sk-child"></div>
   <div className="sk-circle12 sk-child"></div>
   </div> 
 </div> 
      }
      
      </div>
      </div>
    </div>
  </div> </>
  
     );
};
  export default Cricket