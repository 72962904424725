
import { React ,useContext,useState , useEffect} from "react";
 
import  TextInput from '../../../components/TextInput';
import {createBet  }   from '../../../util/rex';
import { AppStateContext }   from '../../../AppState';
import { getUser } from '../../../App/Signin/Auth';
import { json } from "react-router";
const Cricket = () => {
   const [seconds, setSeconds] = useState(8);
   const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { state, updateState } = useContext(AppStateContext);
  const [IsLoading, setIsLoading] = useState(false);
  useEffect(() => {
   const interval = setInterval(() => {
     setSeconds(prevSeconds => prevSeconds - 1);
   }, 1000);

   return () => clearInterval(interval);
 }, []);
 useEffect(() => {
   if (seconds === 0) {
      setSeconds(0);
  
      updateState({ BetData:false });
        // Add any logic to execute when the timer reaches 0
     console.log('Timer reached 0 seconds!');
   }
 }, [seconds]);
  const handleChange = (event) => { 

   let inputValue = event.target.value;

  // Remove leading zeros if the input is a number
  if (/^0[0-9]+$/.test(inputValue)) {
    inputValue = inputValue.replace(/^0+/, '');
  }

     updateState({ BetAmount:inputValue });  
     
    
 };
  const handleIncrease1 = () => {
    if (state.BetAmount < 10) {
        updateState({ BetAmount:10 });  
    }else{  
        updateState({ BetAmount:state.BetAmount+1 });  
    
    }
  };

  const handleDecrease1 = () => {
    if (state.BetAmount > 10) {
        updateState({ BetAmount:state.BetAmount-1 });   
    }
  };
 

  const handleSubmit = (e) => { 

      e.preventDefault();  
       setIsButtonDisabled(true);
        setIsLoading(true);
let Bt_Data=state.BetData;
Bt_Data.Stake=state.BetAmount ;
Bt_Data.Chips=state.authUser.Chips; 
Bt_Data.Block_Betting=state.authUser.Block_Betting; 
 



 createBet(Bt_Data).then(async ress=>{

         updateState( await getUser());
   
  
   updateState({ BetData:false }) 
   
   setIsButtonDisabled(false);
    setIsLoading(false); 



  }).catch(function (error) {
   setIsButtonDisabled(false);
   updateState({ BetData:false }) 
   setIsLoading(false);  

  }); 

    
 

    
  }

 
  
  return ( 
 

<>

   
    <div className="betBox bet-slip-box">
           <span id="msg_error"></span><span id="errmsg"></span>
           <div className="lds-dual-ring  loader" style={{"display": "none"}}></div>
           <div className="bet-box_inner" style={ state.BetData.Type==='LAY'||state.BetData.Type==='NO'||state.BetData.Type==='KHAI'? {"background-color": "rgb(250, 169, 186)"}:{"background-color": "rgb(114, 187, 239)"}}>
              <div style={{"display": "flex", "justify-content": "space-between", "padding": "10px", "margin": "5px", "align-items": "center"}}>
                 <div style={{"font-weight": "700"}}>{ state.BetData.Id }</div>
              </div>
              <div className="inner-bet-section">

              <form className="m-0 " onSubmit={handleSubmit}>
    
   
                 <div className="oddds-stake-box" style={{"margin-bottom": "10px"}}>
                    <div className="items odds">
                       <div className="stack_input_field numbers-row">
                          <TextInput disabled type="number" id="rateValue" className="disabled  form-control " value={ state.BetData.Value }/>
                          <div className="inc plus_btn" >
                             <div className="quantity-button quantity-up"><i className="fas fa-plus"></i></div>
                          </div>
                          <div className="dec plus_btn">
                             <div className="quantity-button quantity-down"><i className="fas fa-minus"></i></div>
                          </div>
                       </div>
                    </div>
                    <div className="items stake" id=" ">
                       <div className="stack_input_field numbers-row">
                          <input type="number" onChange={handleChange}  onMouseLeave={() => { if (!state.BetAmount) { handleChange({ target: { value: 0 } });}
      
  }}  pattern="[0-9]*" step="1" id="stakeValue" className="calProfitLoss stake-input form-control  CommanBtn" value={state.BetAmount}/>
                          <div className="inc plus_btn" onClick={handleIncrease1}>
                              <div className="quantity-button quantity-up"><i className="fas fa-plus"></i></div>
                          </div>
                          <div className="dec plus_btn" onClick={handleDecrease1}>
                             <div className="quantity-button quantity-down"><i className="fas fa-minus"></i></div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div className="bet-btns">
                 {/* {state.Chips ? state.Chips.map((item, index) => (
                           <>     
                    <div className="btn brt_btn"><button className={item.Name} type="button" value={item.Value} onClick={ ()=> {updateState({ BetAmount:item.Value })}}>{item.Name}</button></div>
                            </>  )) :  */}
                                       
    { state.BetData.GType=="odds"? <>    
    <div className="btn brt_btn"><button className=" chipName1" type="button" value="500" onClick={ ()=> {updateState({ BetAmount:100 })}}>100</button></div>
    <div className="btn brt_btn"><button className=" chipName1" type="button" value="500" onClick={ ()=> {updateState({ BetAmount:200 })}}>200</button></div>
    <div className="btn brt_btn"><button className=" chipName1" type="button" value="500" onClick={ ()=> {updateState({ BetAmount:500 })}}>500</button></div>
                    <div className="btn brt_btn"><button className=" chipName1" type="button" value="1000" onClick={  ()=>{updateState({ BetAmount:1000 })}}>1K</button></div>
                    <div className="btn brt_btn"><button className=" chipName1" type="button" value="2000" onClick={  ()=>{updateState({ BetAmount:2000 })}}>2K</button></div>
                    <div className="btn brt_btn"><button className=" chipName1" type="button" value="3000" onClick={  ()=>{updateState({ BetAmount:3000 })}}>3K</button></div>
                    <div className="btn brt_btn"><button className=" chipName1" type="button" value="5000" onClick={ ()=> { updateState({ BetAmount:5000 })}}>5K</button></div>
                    <div className="btn brt_btn"><button className=" chipName1" type="button" value="10000" onClick={ ()=> {updateState({ BetAmount:10000 })}}>10K</button></div>
                    <div className="btn brt_btn"><button className=" chipName1" type="button" value="20000" onClick={  ()=>{updateState({ BetAmount:20000 })}}>20K</button></div>
                    <div className="btn brt_btn"><button className=" chipName1" type="button" value="25000" onClick={  ()=>{updateState({ BetAmount:25000 })}}>25K</button></div>
                    <div className="btn brt_btn"><button className=" chipName1" type="button" value="50000" onClick={  ()=>{updateState({ BetAmount:50000 })}}>50K</button></div>
 
                        <div className="btn brt_btn"><button className=" chipName1" type="button" value="20000" onClick={  ()=>{updateState({ BetAmount:100000 })}}>1L</button></div>
                    <div className="btn brt_btn"><button className=" chipName1" type="button" value="25000" onClick={  ()=>{updateState({ BetAmount:200000 })}}>2L</button></div>
                    <div className="btn brt_btn"><button className=" chipName1" type="button" value="50000" onClick={  ()=>{updateState({ BetAmount:300000 })}}>3L</button></div>
 </>:
 
 <>    
 <div className="btn brt_btn"><button className=" chipName1" type="button" value="100" onClick={ ()=> {updateState({ BetAmount:100 })}}>100</button></div>
 <div className="btn brt_btn"><button className=" chipName1" type="button" value="200" onClick={ ()=> {updateState({ BetAmount:200 })}}>200</button></div>
 <div className="btn brt_btn"><button className=" chipName1" type="button" value="500" onClick={ ()=> {updateState({ BetAmount:500 })}}>500</button></div>
 <div className="btn brt_btn"><button className=" chipName1" type="button" value="1000" onClick={  ()=>{updateState({ BetAmount:1000 })}}>1K</button></div>
 <div className="btn brt_btn"><button className=" chipName1" type="button" value="2000" onClick={  ()=>{updateState({ BetAmount:2000 })}}>2K</button></div>
 <div className="btn brt_btn"><button className=" chipName1" type="button" value="3000" onClick={  ()=>{updateState({ BetAmount:3000 })}}>3K</button></div>
 <div className="btn brt_btn"><button className=" chipName1" type="button" value="5000" onClick={ ()=> { updateState({ BetAmount:5000 })}}>5K</button></div>
 <div className="btn brt_btn"><button className=" chipName1" type="button" value="10000" onClick={ ()=> {updateState({ BetAmount:10000 })}}>10K</button></div>
 <div className="btn brt_btn"><button className=" chipName1" type="button" value="20000" onClick={  ()=>{updateState({ BetAmount:20000 })}}>20K</button></div>
 <div className="btn brt_btn"><button className=" chipName1" type="button" value="25000" onClick={  ()=>{updateState({ BetAmount:25000 })}}>25K</button></div>
 <div className="btn brt_btn"><button className=" chipName1" type="button" value="50000" onClick={  ()=>{updateState({ BetAmount:50000 })}}>50K</button></div>
 
</>
}

                   
                    {/* } */}
                    <div className="btn brt_btn"><button className=" " type="button" onClick={  ()=>{updateState({ BetAmount:0 })}}>Clear</button></div>
                   </div> 



                 <div className="bet-box-footer">
                    <button onClick={()=>{ updateState({ BetData:false }) }}  className="btn cancle-bet" type="button">Cancel {seconds}</button>
                 <button className="btn place-bet"  disabled={isButtonDisabled} type="submit">{IsLoading? 
                 <div role="status" class="spinner-border-sm spinner-border text-light"><span class="sr-only">Loading...</span></div>
                :"Place Bet"}
                 
                 </button>
                 </div> 
            
  </form>
           
           
           
           
           
           
           
              </div>
           </div>
        </div>
    

</>


  );
} 


export default Cricket;
