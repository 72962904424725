import {React,useContext,useState, useEffect} from "react";
import {Route, Switch,Redirect} from "react-router-dom";
 
import { Agrement }   from '../util/config';
import { Button, Modal } from 'react-bootstrap';
import { AppStateContext }   from '../AppState';
import ChangePassword from "./dashboard/ChangePassword/index";
import Dashboard from "./dashboard"; 
import Completed from "./dashboard/completed";
import Account from "./account"; 
 
const App = ({match}) => {
    const { state, updateState } = useContext(AppStateContext);

 
    const [showAgrementModal, setShowAgrementModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
           
          if(  localStorage.getItem("showAgrementModal")){


    setShowAgrementModal(true);

          }  
        };
    
        fetchData();
      }, []); 
    
    
      const handleModalClose = () => {
        localStorage.removeItem("showAgrementModal");
        setShowAgrementModal(false); 
       
      }; 

  return(
    <>
  { state.authUser ?   <Switch> 
    <Route
                path={`${match.url}dashboard/completed/:eid`}
                component={Completed}
              />
       <Route  path={`${match.url}dashboard/change_password`} component={ChangePassword}/>  

     {   state.authUser.Ask_Pass ?
 <Redirect to="/dashboard/change_password" />:
 <>
 
 
  <Route path={`${match.url}/`} component={Dashboard}/>  
    <Route path={`${match.url}dashboard`} component={Dashboard}/>  
    <Route path={`${match.url}account`} component={Account}/>   
  
 
 
   </>
  
  }

     
    </Switch> :
    <div className="text-center my-5 mx-auto" style={{"padding": "25px"}}> 
     <div className="sk-circle">
   <div className="sk-circle1 sk-child"></div>
   <div className="sk-circle2 sk-child"></div>
   <div className="sk-circle3 sk-child"></div>
   <div className="sk-circle4 sk-child"></div>
   <div className="sk-circle5 sk-child"></div>
   <div className="sk-circle6 sk-child"></div>
   <div className="sk-circle7 sk-child"></div>
   <div className="sk-circle8 sk-child"></div>
   <div className="sk-circle9 sk-child"></div>
   <div className="sk-circle10 sk-child"></div>
   <div className="sk-circle11 sk-child"></div>
   <div className="sk-circle12 sk-child"></div>
   </div> 
 </div>
  }
  
  
  
          
          <Modal show={showAgrementModal} onHide={handleModalClose} backdrop="static" keyboard={false}>
        <Modal.Header  >
          <Modal.Title>Rules / नियम</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Agrement/>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={handleModalClose}>
            Agree
          </Button>
         <Button variant="secondary" onClick={()=>{ localStorage.removeItem("token");
    localStorage.removeItem("authUser"); localStorage.removeItem("Login"); 
    location.reload();
   }}>
            Cancel
          </Button>
       </Modal.Footer>
      </Modal>
      
  
  
  </>
  )
 

};

export default App;
