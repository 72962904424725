
 
import React, {   useState } from "react";
import Odds from './Odds';
import Score_Card from './Score_Card';
import TV from './t_v';

import { useParams  } from "react-router-dom";
const Events = () => {
   
  const [ShowTV, setShowTV] = useState(false);
  
  const { eid } = useParams() 

  const handleSwitchTV = () => {
   
    setShowTV(!ShowTV);  
  };
  return (
    <div>
      <div>
        <div className="match-box">
        
          <div class="mod-header mod-header2">
            <div class="select-tv-ico">
              <span class="badge badge-warning" onClick={()=>window.history.back()}>Back</span>
              <div style={{ "float": "right" }} onClick={()=>handleSwitchTV()}> 
               <time> <img src="https://urexch.in/assets/images/live-tv.png"/> </time>
                <span>Live Tv</span>
                </div>
                
                </div>
                </div>
          <div  style={{"background-color": "#000",    "text-align": "center"}} className={"collapse " +(ShowTV==true?'show':null)}>
{ShowTV==true?<TV/>:null

}

          </div>
        </div>
     
        <div className="match-box scoreB" style={{ "font-size": "12px" }}>


          
           <Score_Card />
          </div>

          <div className="match-box">   
    
    <div className="tabel-scroll-2">
     <table className="market-listing-table">
       
       <Odds   />
       </table>


</div>


</div>


      </div>  
      <div className="match-box text-center" style={{"padding": "10px"}}><a href={"/dashboard/completed/"+eid}>See All Completed Bets</a></div>
 
      <div style={{ "padding-bottom": "50px", "margin-bottom": "50px" }}>
&nbsp;
        
      </div>
       </div>
  );
}

export default Events;
