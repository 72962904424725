  
import {React,useContext} from "react"; 
  
import { useParams  } from "react-router-dom";
import { AppStateContext }   from '../../../../AppState';
const Score_Card = () => {  
  
  const { state, updateState } = useContext(AppStateContext);

  const { eid } = useParams();
   return (
    
     <iframe src={'https://score.yply.xyz:4025?eid='+eid}  class={state.FS_Scoreboard}></iframe>
      
        
  );
}

export default Score_Card;
