import React, { useContext,useEffect,useState } from "react";   
import {io} from 'socket.io-client'; 

import { AppStateContext }   from '../../../../AppState';

const TeenPatti_T20 = ({ match }) => { 
   const { state, updateState } = useContext(AppStateContext);


   const [CircleCards, setCircleCards] = useState({});
  const [ws, setWs] = useState(null);

  const  circleCode  = "teenpattit20";  

  const findPropertyValueById=(array, id, property)=> {
     const foundObject = array.find(obj => obj.nat === id);
     return foundObject ? foundObject[property] : undefined;
   }
  useEffect(() => {
     const createWebSocket = () => {
      const circleKey="teenpatti_t20";
  
      const ioClient = io.connect('wss://circle-data.yply.xyz:4026', { 
       rememberUpgrade:true,
       transports: ['websocket'],
       secure:true, 
       rejectUnauthorized: false,
        withCredentials: true,
               transports: ['websocket'], reconnection: true,
               reconnectionDelay: 1000,
               reconnectionDelayMax: 5000,
               reconnectionAttempts: 999999
           });
          
        
           ioClient.emit('casino', circleKey);
         
           ioClient.io.on('reconnect', function() {
             ioClient.emit(casino, circleKey);
 })
 
 
 
 // General error handling
 ioClient.on('error', (err) => {
    console.error('Socket error:', err);
  });
  
  // Handle connection errors
  ioClient.on('connect_error', (err) => {
    console.error('Connection error:', err);
  });
  
  // Handle connection timeouts
  ioClient.on('connect_timeout', () => {
    console.error('Connection timeout');
  });
  
  // Handle disconnection
  ioClient.on('disconnect', (reason) => {
    console.warn('Disconnected:', reason);
    if (reason === 'io server disconnect') {
      // The disconnection was initiated by the server, you can attempt to reconnect
      ioClient.connect();
    }
  });
 
 
 
 
 ioClient.on('casino/'+circleKey  , function(data){
   const newMessage = data;
   const Cards = { C1: newMessage.t1[0].C1, C2: newMessage.t1[0].C2, C3: newMessage.t1[0].C3, C4: newMessage.t1[0].C4, C5: newMessage.t1[0].C5, C6: newMessage.t1[0].C6,    Data: data };

setCircleCards(Cards);

 });
      
       
      
        return ioClient;
      
    
    
    };
    
   
      
      // Initial WebSocket connection
      let webSocket = createWebSocket();
      setWs(webSocket);
      
      // Cleanup function
      return () => {
        webSocket.close();
      };
      
  }, []);
  const authUser =JSON.parse(localStorage.getItem('authUser'));

  const OpenBetBoard= async(data)=>{
 
     
     updateState({ BetData:data });
    updateState({ BetAmount:0 }); 
 
  } 
return (
    <>
    <div class="ab2-main casino">
    <div class="match-box">
       <div class="mod-header">
          <div class="select-tv-ico"><span>Teen Patti 20-20</span><span style={{"float": "right"}}>{CircleCards.Data?"Round Id : "+CircleCards.Data.round_id:0}</span></div>
       </div>
    </div>
    <div>
       <div class="col-12 col-md-12" style={{"padding": "0px"}}>
          <iframe class="live-str" src={`https://tv.yply.xyz/play.html?id=${circleCode}`} height="400" width="100%" scrolling="auto" style={{"background-color": "black"}}></iframe>
          <button class="rules-btn">
           <img style={{"width": "20px"}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABMlBMVEUAAAAAgJIJfo8If5EIgJAIgJAIgJAIgJAJgJEAdokHgI0If5EIf5AIgJAHfJEAcY4HgJAIf5AHgJAAgJ8If5AIf5AKeo8KfZIIf5AGgJALgJUIf5AIfpEKfZEJfpAIgJAAbZIIgJAHfo8If5AIf5AAgIAFfZIIf5AHfo4JgJEJgJEIgJAIfpEKgI8Hf5AAgJUJfpAIgJAIgJAIf48If5AHgJALepAOgI4IgJAIgJAIf5AJgJEIfpAHgJEIgJAIf5AIf5AIfpAAgI8If5EIf5AJgJEMeZIHf48IgJAIgI8If5AIgJAAgIAJfpAIgJAGgJEHf5EIgJAIgI8IgI8IgJAAgIAIf5AIgJAIfY4IgJEIf5AIf5AIgJAIf5AJe44GfY4If5AIgJAIf5AJgJEIf5AAAADnHCSFAAAAZHRSTlMADlmbyur6mlgNJp31nCUJjPuKCNPHGTHjLhjnXzNx9Ac+aYH9Bi/zTXiW8n1QjwxX2PiZ1WwXEqbI63ZjSO7p+f4Qyd/SFYtcRKOYAlW4LI324CLcBIfUPYbhxeLlGy3BxpdWz+XyAwAAAAFiS0dEAIgFHUgAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQflAhMFDybhb9BZAAABZElEQVQ4y32T2VrCQAyFI5QCsgqiUNSioCgqILiBaIsLLogbbijuff9nMK06TaCfuTs5/0zSaQIgYsTlljyy7JG8Pj8Mx2ggaIgIhsIDdiQ6ZrCQY3Hqh8eNoUhM2P6kx3CIZEqcd/SRUH7r0/vTU9MzqqiSsYAoPTWLiTmhstb30f5z85hZEDJvFgmwuouYWbJlAd8vyIDlldUiuVItgcv4N8rgZjq9VuFAFSRbrG9sAmxtM6AGdVvsNHaxxfgeBTTQqWzuI3FAMxUO5CIIHHKgTuUR+q0mLyFReYzACWsyAV4qTxE4Y0AbfESp+PfONQZ0wE+e+gIvuGQ+PjWEbHmFQIcBXnOeZCGL5vjlr29EQu+aAxET+tbahzu7zXtrouIJkXhoPHZ6T0I+/4wcKH3DMV5e/8Y6lXTy+2/2YrTeh/0Pha5WJqtyW+9lBrZTKRBErXYd9rtUbtc0Xddqn18lO/sNxUwSHR4NB6YAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDItMTlUMDU6MTU6MzgrMDA6MDCyNS9NAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTAyLTE5VDA1OjE1OjM4KzAwOjAww2iX8QAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII="/></button>
           <div class="flipclock"><span class="flipclock-t1 fold">{CircleCards.Data?CircleCards.Data.t1[0].autotime.padStart(2, '0').replace(/^(0)(\d)/, '$1 $2') :"0 0"}</span></div>
           <div class="tp-res-box">
            <div class="col-md-12 col-12 andr-bhar-res-text andarbahar-res">
               Player A
               <div class="rescard andar">
                  <img src={"https://urexch.in/cards/"+(CircleCards?CircleCards.C1:1)+".png"} class="andar-bahar-image"/>
                  <img src={"https://urexch.in/cards/"+(CircleCards?CircleCards.C2:1)+".png"} class="andar-bahar-image"/>
                  <img src={"https://urexch.in/cards/"+(CircleCards?CircleCards.C3:1)+".png"} class="andar-bahar-image"/>
                  </div>
                  </div>
                  <div class="col-md-12 col-12 andr-bhar-res-text andarbahar-res">
                     Player B
                     <div class="rescard  bahar">
                        <img src={"https://urexch.in/cards/"+(CircleCards?CircleCards.C4:1)+".png"} class="andar-bahar-image"/>
                        <img src={"https://urexch.in/cards/"+(CircleCards?CircleCards.C5:1)+".png"} class="andar-bahar-image"/>
                        <img src={"https://urexch.in/cards/"+(CircleCards?CircleCards.C6:1)+".png"} class="andar-bahar-image"/>
                        </div>
                        </div>
                        </div>
       </div>
    </div>
    <div class="row mr-left-right lucky7-main" style={{"justify-content": "center", "padding": "0px"}}>
   <div class="col-md-12 mobile-tp-border" style={{"display": "flex", "padding": "0px"}}>
      <div class="col-md-8 col-8  mobile-tp-text "></div>
      <div class="col-md-4 col-4  tp-bhav" style={{"text-align": "center", "float": "right"}}>Back</div>
   </div>
   <div class="col-md-12 mobile-tp-border" style={{"display": "flex", "padding": "0px", "background": "rgb(162, 162, 162)"}}>
      <div class="col-md-8 col-8  mobile-tp-text casino-profit-text" style={{"color": "rgb(255, 255, 255)"}}>Player A</div>
      {CircleCards.Data&&(CircleCards.Data.t2[0].gstatus==="0"||CircleCards.Data.t2[0].gstatus==="CLOSED"||CircleCards.Data.t2[0].gstatus==="SUSPENDED")?
  
      <div class="col-md-4 col-4  tp-bhav " style={{"text-align": "center", "padding": "0px"}}>
         <div class="tp-bhav" style={{"text-align": "center", "display": "grid", "padding": "0px"}}>
            <span>0.00</span><span>0</span>
            <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
         </div>
      </div>
:
<div class="col-md-4 col-4  tp-bhav "
onClick={async()=>{
               
               OpenBetBoard({
                  Value:"0.97",
                  Type:"PLAYER A", 
                  Round:CircleCards.Data.round_id, 
                  Game:"teenpattit20", 
                  U_ID:authUser.ID, 
                  Id:"Player A",
                  SelectionId:CircleCards.Data.t2[0].sid,
                   Size:0,
                     Sport:"Casino",
                   GType:"Teenpatti 20-20"  
                 })
              }
               }  style={{"text-align": "center", "padding": "0px"}}>
         <div class="tp-bhav" style={{"text-align": "center", "display": "grid", "padding": "0px"}}>
            <span>0.97</span><span>0</span>
           </div>
      </div>

}

   </div>
   <div class="col-md-12 mobile-tp-border" style={{"display": "flex", "padding": "0px", "background": "rgb(162, 162, 162)", "color": "rgb(255, 255, 255)"}}>
      <div class="col-md-8 col-8  mobile-tp-text casino-profit-text" style={{"color": "rgb(255, 255, 255)"}}>Player B</div>
      {CircleCards.Data&&(CircleCards.Data.t2[2].gstatus==="0"||CircleCards.Data.t2[2].gstatus==="CLOSED"||CircleCards.Data.t2[2].gstatus==="SUSPENDED")?
  
  <div class="col-md-4 col-4  tp-bhav " style={{"text-align": "center", "padding": "0px"}}>
     <div class="tp-bhav" style={{"text-align": "center", "display": "grid", "padding": "0px"}}>
        <span>0.00</span><span>0</span>
        <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
     </div>
  </div>
:
<div class="col-md-4 col-4  tp-bhav "  onClick={async()=>{
               
               OpenBetBoard({
                  Value:"0.97",
                  Type:"PLAYER B", 
                  Round:CircleCards.Data.round_id, 
                  Game:"teenpattit20", 
                  U_ID:authUser.ID, 
                  Id:"Player B",
                  SelectionId:CircleCards.Data.t2[2].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Teenpatti 20-20"  
                 })
              }
               } 
               
               style={{"text-align": "center", "padding": "0px"}}>
     <div class="tp-bhav" style={{"text-align": "center", "display": "grid", "padding": "0px"}}>
        <span>0.97</span><span>0</span>
       </div>
  </div>

}
   </div>
</div>
   
    <div style={{"margin-left": "0px", "margin-right": "0px"}} class="row">
       <div class="match-box">
          <div class="mod-header">
             <div class="select-tv-ico"><span>Last Result</span><span style={{"float": "right"}}><a style={{"color": "rgb(255, 255, 255)"}} href="/account/ac-statement">View All</a></span></div>
          </div>
       </div>
       <div class="Result" style={{"color": "white", "background-color": "rgb(255, 255, 255)", "text-align": "right", "width": "100%"}}>
           <span class="game-result playera">A</span>
           <span class="game-result playera">A</span>
           <span class="game-result playerb">B</span>
           <span class="game-result playera">A</span>
           <span class="game-result playerb">B</span>
           <span class="game-result playera">A</span>
           <span class="game-result playerb">B</span>
           <span class="game-result playerb">B</span>
           <span class="game-result playerb">B</span>
           <span class="game-result playerb">B</span>
           </div>
    </div>
    </div>
  

 </>
   )


};

export default TeenPatti_T20;
