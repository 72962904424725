  
import React from "react"; 

import Sidebar from './Sidebar'; 
 
 
import {useRouteMatch} from "react-router-dom";
import App from "../../../routes/index";
 
const MainWrapper = () => { 
   const match = useRouteMatch();
   return [ 
  
    <div class="main-wrapper">
      <div class="wrapper " ><Sidebar /><App match={match} />
      
      
      </div></div> 
   
   ];
}

export default MainWrapper;
