import { React, useEffect, useContext ,useState} from 'react';
import { getStatement } from '../../../util/rex';
import Moment from 'moment';

import { AppStateContext } from '../../../AppState';
const Statement = () => {
      const [ Statement, setStatement ] = useState([]);


    useEffect(() => {
        const fetchData = async () => {

        const _statement= await getStatement();
        setStatement(_statement); 
        
        };

        fetchData();
    }, []);

    return (
        <div className="main-content">
            <div className="main-inner">
                <section className="accountste match-content">
                    <div className="table_tittle">
                        Account Statement
                        <div className="pull-right"><button onClick={() => history.back()} className="btn btn-xs btn-primary pull-right">Back</button> </div>
                    </div>
                    <div className="col-md-12"></div>
                    <div className="select_account">
                        <div className="radio"><input id="radio-1" checked type="radio" name="fltrselct" value="0" /><label for="radio-1" className="radio-label">All</label></div>
                        <div className="radio"><input id="radio-4" type="radio" name="fltrselct" value="3" /><label for="radio-4" className="radio-label">Profit and Loss</label></div>
                        <div className="radio"><input id="radio-2" type="radio" name="fltrselct" value="1" /><label for="radio-2" className="radio-label">PDC</label></div>
                        <div className="radio"><input id="radio-5" type="radio" name="fltrselct" value="4" /><label for="radio-5" className="radio-label">Account Statement</label></div>
                    </div>
                    <div className="row form-horizontal">
                        <div className="col-md-3 col-xs-6"><input type="date" name="fdate" id="fdate" max="2023-06-04" className="form-control" placeholder="From Date" autocomplete="off" value="04-06-2023" /></div>
                        <div className="col-md-3 col-xs-6"><input type="date" name="tdate" id="tdate" max="2023-06-04" className="form-control" placeholder="To Date" autocomplete="off" value="04-06-2023" /></div>
                        <div className="col-md-4 col-xs-6"><button type="submit" name="submit" className="btn btn-success" data-attr="submit">Filter</button><a href="#" className="btn btn-danger">Clear</a></div>
                    </div>
                    <div id="divLoading"> </div>
                    <div className="appendAjaxTbl">
                        <div className="table-responsive sports-tabel">
                            <table className="table table-bordered table-hover" id="datatabless">
                                <thead>
                                    <tr>
                                        <th className="">S.No. </th>
                                        <th className="">Date </th>
                                        <th className="">Description </th>
                                        <th className="text-right">Prev. Bal </th>
                                        <th className="text-right">Credit</th>
                                        <th className="text-right">Debit </th>
                                        <th className="text-right">Comm+ </th>
                                        <th className="text-right">Balance </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Statement ? Statement.map((item, index) => (
                                        <tr key={item.ID} className="heading_user_table">
                                            <td>{item.S_No}</td> 
                                          <td>{ item.Date }</td>
                                         <td><strong>{item.Description}</strong></td>
                                            <td className="text-right">{parseFloat(item.Prev_Bal).toFixed(2) }</td>
                                            <td className="green text-right">{parseFloat(item.Credit).toFixed(2)}</td>
                                            <td className="red text-right">{parseFloat(item.Debit).toFixed(2)}</td>
                                            <td className="green text-right">{parseFloat(item.Commission).toFixed(2)}</td>
                                            <td className="text-right">{parseFloat(item.Balance).toFixed(2)}</td>
 
                                        </tr>
                                    )) : null}
                                </tbody>
                            </table>
                            <div style={{ "text-align": "center" }}>
                                <ul className="pagination">
                                    <li className="previous disabled"><a tabindex="0" role="button" aria-disabled="true">Prev</a></li>
                                    <li className="active"><a role="button" tabindex="0" aria-label="Page 1 is your current page" aria-current="page">1</a></li>
                                    <li className="next disabled"><a tabindex="0" role="button" aria-disabled="true">Next</a></li>
                                </ul>
                            </div>
                        </div>
                        <p id="paginateClick" className=""> </p>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Statement;