import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



const Owl = () => { 
    const options = {
        items: 1, // Display one item at a time
        loop: true, // Enable loop
        nav: true, // Display navigation buttons
        dots: true, // Hide pagination dots
        autoplay: true, // Enable autoplay
        autoplayTimeout: 3000, // Autoplay interval in milliseconds
        navText: ['<', '>'], // Custom navigation text
        responsive: {
          0: {
            // Responsive settings for smaller screens
            nav: false, // Hide navigation buttons
          },
          768: {
            // Responsive settings for medium screens
            nav: false, // Display navigation buttons
          },
        },
      };
return(<OwlCarousel  className="owl-theme" {...options} margin={10} nav>
  <div class='item' style={{"max-height": "460px"}} >
    <img className="w-100" src="https://images.ottplay.com/images/7bdad4b39e4c4429295af71fbc779d67.jpg" />
    </div> 
    <div class='item' style={{"max-height": "460px"}} >
    <img className="w-100"  src="https://urbet.in/assets/images/banners/bn6.png" />
  
    </div> 
    <div class='item' style={{"max-height": "460px"}}>
    <img className="w-100"  src="https://www.thepaksports.com/wp-content/uploads/2021/07/slidebanner02-casino.jpg" />
  
    </div> 
    {/* <div class='item' style={{"max-height": "460px"}}>
    <img className="w-100"  src="https://ik.imagekit.io/blockster/girl-in-casino-1.jpg" />
  
    </div>  */}
    
    <div class='item' style={{"max-height": "460px"}}>
    <img className="w-100"  src="https://plainenglish.io/gambling/wp-content/themes/generic/images/fullwidth/baccarat-roulette-blackjack.jpg" />
  
    </div> 
    
    
</OwlCarousel>);

};

export default Owl;