import React, { useState,useEffect, useContext } from 'react';
 
import {GetChipsStake, SetChipsStake }   from '../util/rex'; 
import { AppStateContext }   from '../AppState';
import { RulesEnglish,RulesHindi }   from '../util/config';
import { Button, Modal } from 'react-bootstrap';
const RulesModel = () => {
    
  const { state, updateState } = useContext(AppStateContext); 

  const [showModal, setShowModal ] = useState(false);

  const [InHindi, setInHindi ] = useState(true);

  useEffect(() => {
   const fetchData = async () => {
      
      updateState( await GetChipsStake());
 
   };

   fetchData();
 }, []); 




  const handleModalOpen = () => {
     setShowModal(true); 
   };

  const handleModalClose = () => {
    setShowModal(false);
  }; 

  const handleSubmit = async(e) => {
   e.preventDefault();
   let formData ={
      Name1:document.getElementById("Name1").value,
      Value1:document.getElementById("Value1").value,

      Name2:document.getElementById("Name2").value,
      Value2:document.getElementById("Value2").value,

      Name3:document.getElementById("Name3").value,
      Value3:document.getElementById("Value3").value,

      Name4:document.getElementById("Name4").value,
      Value4:document.getElementById("Value4").value,

      Name5:document.getElementById("Name5").value,
      Value5:document.getElementById("Value5").value,

      Name6:document.getElementById("Name6").value,
      Value6:document.getElementById("Value6").value,
   }

   await  SetChipsStake(formData).then(async(resp)=>{

      if (resp.success===true){ 
         updateState( await GetChipsStake());
      
  setShowModal(false); 
         }
          
    }) ;
   

 }  ; 

  return (
<>  
<span  onClick={handleModalOpen}  style={{ "float": "right", "background": "rgb(255, 255, 255)", "border-radius": "15px", "padding": "0px 6px", "color": "rgb(8, 131, 148)" }}>𝓲</span>
        
  
   <Modal show={showModal} onHide={handleModalClose}  backdrop="static" keyboard={false}>
     <form className="m-0 " onSubmit={handleSubmit}>
  <Modal.Header   >
     <Modal.Title>Rules</Modal.Title>
     <button type="button" className="close"  onClick={handleModalClose} aria-label="Close"><span aria-hidden="true">×</span></button>
   </Modal.Header>
   <Modal.Body>
   <div style={{"display": "flex", "justify-content": "center"}}>
    <div class="row">
        <div onClick={ ()=>{setInHindi(true)}} id="hindi" className="btn mx-1  btn-info">
            <span>Hindi</span>
            </div>
            <div onClick={ ()=>{setInHindi(false)}} id="english" className="btn mx-1 btn-info">English</div>
            </div>
            </div>
{InHindi?
<RulesHindi/>
:

<RulesEnglish/>}
            
   
   </Modal.Body>
   <Modal.Footer> 
     <Button  variant="outline-primary" onClick={handleModalClose}>
       Close
     </Button> 
   </Modal.Footer>
 </form>
   </Modal>
 
    </>
  
  );
}; 

export default RulesModel;