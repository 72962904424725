import {React,useState,  useEffect,useContext} from 'react';
import {io} from 'socket.io-client';
import { AppStateContext }   from '../../../../AppState';
 
 
import  OddsBet from "../../../../components/OddsBet";
 
import  _Popover from "../../../../components/Popover";
  
import RulesModel from "../../../../components/RulesModel";
import { useParams  } from "react-router-dom";
 
const ioClient = io.connect('wss://gems.urbet.online:9792', { 
rememberUpgrade:true,
transports: ['websocket'],
secure:true, 
rejectUnauthorized: false,
 withCredentials: true,
        transports: ['websocket'], reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 999999
    },'https://urbet.online');
 
export const   Odds = () => {  
  const { state, updateState } = useContext(AppStateContext);

  const { eid } = useParams() 
  const [ShowFancy, setShowFancy] = useState(false); 
  const [ShowExFancy, setShowExFancy] = useState(false); 
  const [MatchRunners, setMatchRunners] = useState([]); 
    const [Gems, setGems] = useState([]); 
  const [BM, setBM] = useState([]); 
  const [Fancy, setFancy] = useState([]);   
   
  const findPropertyValueById=(array, id, property)=> {
    const foundObject = array.find(obj => obj.selectionid === id);
    return foundObject ? foundObject[property] : undefined;
  }
  useEffect( async() => {
    
   updateState({FS_Scoreboard:'scoreboard-iframe'})
  ioClient.emit("gems", eid);  
    
  ioClient.io.on('reconnect', function () {
    
  ioClient.emit("alwarevents", eid);  
            
  }) 

  ioClient.on('gems/'+eid, function (data) { 
    if(data===null){
     history.back()
    }else{  
     setGems(JSON.parse(data));
    const match_runners=JSON.parse(JSON.parse(data).match_runners);
      setMatchRunners(match_runners)
    
      
    };
    
  })
  
  ioClient.on('bm_odds',async function ([odds]) {
 
let dta=[];

Object.keys(odds).forEach(function(objKey) {
    dta.push (odds[objKey])  ; 
}) 
 
   
     setBM(dta);  
}) 
 
  ioClient.on('autofancy',async function ([fancy]) {
    
   if(fancy.length){
     setFancy(fancy);  }
  })
    
  },[],  [setBM,setFancy,setGems,updateState]); 
 
   
  const authUser =JSON.parse(localStorage.getItem('authUser'));
  
const OpenBetBoard= async(data)=>{
   updateState({ BetData:data });
  updateState({ BetAmount:0 }); 
} 
// "{\"id\":6111,\"event_id\":\"32383724\",\"market_id\":\"1.214707287\",\"event_name\":\"Chennai Super Kings v Gujarat Titans\",\"match_runners\":\"[{\\\"name\\\":\\\"Chennai Super Kings\\\",\\\"selectionId\\\":2954263},{\\\"name\\\":\\\"Gujarat Titans\\\",\\\"selectionId\\\":42821394}]\",\"open_date\":\"05/28/2023 07:30:00 PM\",\"unix_timestamp\":\"1685302200\",\"competition_id\":\"101480\",\"competition_name\":\"Indian Premier League\",\"sport_id\":\"4\",\"sport_name\":\"Cricket\",\"winner_selection_id\":null,\"bf_status\":\"OPEN\",\"status\":\"Active\",\"match_type\":\"Auto\",\"score_type\":null,\"fancy_type\":\"Auto\",\"match_min\":200,\"match_max\":200000,\"fancy_min\":200,\"fancy_max\":50000,\"tv_id\":null,\"is_mbds_info\":0,\"message\":\"\",\"bet_status\":\"play\",\"masters\":\"[]\",\"inplay_bet\":0,\"created_at\":\"2023-05-26T18:40:04.000000Z\",\"updated_at\":\"2023-05-27T17:55:21.000000Z\"}"
  
  return (  
     
<>
<thead>
           <tr>
             <th colspan="7" className="marketTitle" style={{ "text-align": "left" }}><span className="match-name-team">{Gems.event_name+' /'}<span className="blinking">Match Odds</span></span><span className="no_border" style={{ "margin-left": "0px"}}> Min :{Gems.match_min}</span><span className="no_border">Max :{Gems.match_max+100000}</span> 
             <span  onClick={() => (state.FS_Scoreboard==="scoreboard-iframe-full")?updateState({FS_Scoreboard:'scoreboard-iframe'}) : updateState({FS_Scoreboard:'scoreboard-iframe-full'}) } style={{"height":"20px", "position": "absolute", "right": "0px", "top": "1px", "bottom": "12px", "padding": "2px", "text-align": "center", "background": "rgb(226, 198, 6)", "border-radius": "10px", "width": "60px"}}>SCORE</span></th>
           </tr>
         </thead>
         <div className="fancy-table">
           <div className="fancy-heads" style={{ "background-color": "rgb(255, 255, 255)" }}>
             <div className="event-sports"></div>
             <div className="fancy_buttons">
               <div className="fancy-backs head-no" style={{ "color": "rgb(0, 0, 0)" }}>LAGAI <i className="fas fa-arrow-circle-down" style={{ "color": "rgb(114, 187, 239)" }}></i></div>
             </div>
             <div className="fancy_buttons">
               <div className="fancy-lays head-yes" style={{ "color": "rgb(0, 0, 0)" }}>KHAI <i className="fas fa-arrow-circle-down" style={{ "color": "rgb(250, 169, 186)" }}></i></div>
             </div>
           </div>
         </div>
 {(MatchRunners.length>0) ?   
<>

{Object.keys(MatchRunners).map((innerAttr, index) => {

return (


 

<>



      <div className="f-prio2">
        <div className="fancy-rows list-item">
          <div className="event-sports event-sports-name">
            <div className="event-name" style={{ "margin-left": "0px", "color": "rgb(0, 0, 0)", "font-weight": "700", "display": "block" }}>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAbwAAAG8B8aLcQwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEkSURBVDiNxdM9L0RBFMbx31krItbGayJBFDYoFURofQefQKURpULhc2h0ItFQKkRHofQNKCQSkXgJBVdxZzfXzS2IwiSTzPznzMx5njkTWZb5S6v9aTfqPwmKiBpOMYgXdGMly7JMuaOB1Qo+jW084aPDS0EDuMAbDtGXeGAHD9jEXXtPrZDmCM5wgyk0cRkRCzjBGpbSBbcdfemGJq6xj67EatjFJw7a2aS1wW8SMJr0BfqxVQgebsuo9KzCrPmixgI/x2SZFz0Yq3i+mYhopekchiOiFRF77ZhiIR1HxHLpjA2sl9gEFqsO6En9V60eEeNyE3vRkhdKPSLmE39P4zpm5Sb3JnYfOMKCvIie5c82IC+aPmR4xRAeU9aNNL6Kf/+NX1/6sbpQZ7DqAAAAAElFTkSuQmCC" /> 
              
               {" "+MatchRunners[innerAttr].name}
               
               <br />
               <OddsBet Selection={MatchRunners[innerAttr].selectionId}/>
            </div>
          </div>
          <div className="fancy_div">
            <span className="fbox35270">
              <div className="fancy_buttone">
                <div className="fancy-backs bet-button back mark-back" style={{ "padding": "15px", "user-select": "none" }}  onClick={async()=>{
{findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'rate1')>0?OpenBetBoard({
 Value:findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'rate1'),
 Type:"LAGAI", 
 Event:eid, 
 U_ID:authUser.ID, 
 Id:findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'team') ,
 SelectionId:findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'selectionid') ,
  Size:findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'team') ,
  GType:"odds"  
}):null}}}>

  <strong>  {findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'rate1')}</strong></div>
                <div className="fancy-lays bet-button lay mark-lay" style={{ "padding": "15px", "user-select": "none" }} onClick={async()=>{
 {findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'rate2')>0?OpenBetBoard({
 Value:findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'rate2'),
 Type:"KHAI", 
 Event:eid, 
 U_ID:authUser.ID, 
 Id:findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'team') ,
 SelectionId:findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'selectionid') ,
 Size:findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'team')  ,
  GType:"odds"  
}):null}}}>
  <strong>  {findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'rate2')}</strong></div>
              </div>
            </span>
            {((!findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'rate1')>0  && !findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'rate2')>0) || findPropertyValueById(BM,MatchRunners[innerAttr].selectionId,'stop')==="yes")?
            
              <div className="ball-running-msg">
              <h1>SUSPENDED</h1>
            </div>
          
            :null}
          </div>
        </div>
      </div>
      
   </>

)


}) }
</> 

:null}
  
{(Fancy.length>0) ?    
<div className="match-box">
  <div style={{ "display": "block"}}>
    
   
    <div className="fancy-table">
      <div className="fancy-heads">
        <div className="event-sports">
          <span className="marketTitle blinking">Fancy 
          <RulesModel/>
          
          </span><span className="no_border" style={{ "margin-left": "0px" }}>Min :{Gems.fancy_min-100}</span><span className="no_border">Max :{Gems.fancy_max}</span>
          </div>
        <div className="fancy_buttons">
          <div className="fancy-backs head-no"><strong>NO</strong></div>
        </div>
        <div className="fancy_buttons">
          <div className="fancy-lays head-yes"><strong>YES</strong></div>
        </div>
      </div>
    </div> 


    <div className="fullrow margin_bottom fancybox">

    {Object.keys(Fancy).map((innerAttr, index) => {

return (
<> 
   {Fancy[innerAttr].isComm?
  

      <div className="f-prio2">
        <div className="fancy-rows list-item">
          <div className="event-sports event-sports-name">
            <span className="event-name" style={{ "margin-left": "0px", "color": "rgb(0, 0, 0)", "font-weight": "700", "font-size": "13px", "display": "block" }}>
               
          {Fancy[innerAttr].RunnerName}
           <_Popover/>
            
            </span>
            </div>
          <div className="fancy_div">
            <span>
              <div className="fancy_buttone">
               
              {(( !Fancy[innerAttr].LayPrice1>0  && !Fancy[innerAttr].BackPrice1>0) || Fancy[innerAttr].isSuspended)?<>
               
                <div className="fancy-lays bet-button lay mark-lay">
               
                  <strong>0</strong>
                  <div className="size"><span>0</span></div>
                </div>
                <div className="fancy-backs bet-button back mark-back">
                  <strong>0</strong>
                  <div className="size"><span>0</span></div>
                </div></>
:<>
               
<div className="fancy-lays bet-button lay mark-lay"  onClick={async()=>{OpenBetBoard({
 Value:Fancy[innerAttr].LayPrice1,
 Type:"NO", 
 Event:eid, 
 U_ID:authUser.ID, 
 Id:Fancy[innerAttr].RunnerName,
  Size:Fancy[innerAttr].LaySize1,
  GType:"session"  
})}}>

  <strong>{Fancy[innerAttr].LayPrice1}</strong>
  <div className="size"><span>{Fancy[innerAttr].LaySize1}</span></div>
</div>
<div className="fancy-backs bet-button back mark-back" onClick={async()=>{OpenBetBoard({
 Value:Fancy[innerAttr].BackPrice1,
  Type:"YES",   
 Event:eid, 
  U_ID:authUser.ID,  
  Id:Fancy[innerAttr].RunnerName,
  Size:Fancy[innerAttr].BackSize1,
  GType:"session"  
})}}>
  <strong>{Fancy[innerAttr].BackPrice1}</strong>
  <div className="size"><span>{Fancy[innerAttr].BackSize1}</span></div>
</div></>}


              </div>
            </span>

            {(( !Fancy[innerAttr].LayPrice1>0  && !Fancy[innerAttr].BackPrice1>0) || Fancy[innerAttr].isSuspended)?
            
            <div className="ball-running-msg">
           <h1>{Fancy[innerAttr].GameStatus===""?"SUSPENDED":Fancy[innerAttr].GameStatus}</h1>
          </div>
        
          :null}

 



          </div>
        </div>
        <div class="fancy-txt">{Fancy[innerAttr].message}</div>
      </div>
      


:null}
   

      </>
)


}) }

 
    </div>

 
    <div className="fancy-table">
      <div className="fancy-heads">
        <div className="event-sports"><span className="marketTitle blinking">Extra Fancy</span><span className="no_border" style={{ "margin-left": "0px" }}>Min :{Gems.fancy_min-100}</span><span className="no_border">Max :{Gems.fancy_max}</span></div>
        <div className="fancy_buttons">
          <div className="fancy-backs head-no"><strong>NO</strong></div>
        </div>
        <div className="fancy_buttons">
          <div className="fancy-lays head-yes"><strong>YES</strong></div>
        </div>
      </div>
    </div>
 
    <div className="fullrow margin_bottom fancybox">

{Object.keys(Fancy).map((innerAttr, index) => {

return (
<>
{(!Fancy[innerAttr].RunnerName.toLowerCase().includes('bhav') && !Fancy[innerAttr].RunnerName.toLowerCase().includes('ball run'))? <>
{!Fancy[innerAttr].isComm? 
  <>
{((Fancy[innerAttr].LayPrice1>10  && Fancy[innerAttr].BackPrice1>10) &&  Fancy[innerAttr].isSuspended===false && Fancy[innerAttr].enabled ==="Active")?
 

  <div className="f-prio2">
    <div className="fancy-rows list-item">
      <div className="event-sports event-sports-name"><span className="event-name" style={{ "margin-left": "0px", "color": "rgb(0, 0, 0)", "font-weight": "700", "font-size": "13px", "display": "block" }}>
           
      {Fancy[innerAttr].RunnerName}
       
      <_Popover/>
        
                  
                  </span></div>
      <div className="fancy_div">
        <span>
          <div className="fancy_buttone">
            <div className="fancy-lays bet-button lay mark-lay" onClick={async()=>{OpenBetBoard({
 Value:Fancy[innerAttr].LayPrice1,
  Type:"NO",   
 Event:eid, 
  U_ID:authUser.ID,  
  Id:Fancy[innerAttr].RunnerName,
  Size:Fancy[innerAttr].LaySize1,
  GType:"session"  
})}}>
           
              <strong>{Fancy[innerAttr].LayPrice1}</strong>
              <div className="size"><span>{Fancy[innerAttr].LaySize1}</span></div>
            </div>
            <div className="fancy-backs bet-button back mark-back" onClick={async()=>{OpenBetBoard({
 Value:Fancy[innerAttr].BackPrice1,
  Type:"YES",   
 Event:eid, 
  U_ID:authUser.ID,  
  Id:Fancy[innerAttr].RunnerName,
  Size:Fancy[innerAttr].BackSize1,
  GType:"session"  
})}}>
              <strong>{Fancy[innerAttr].BackPrice1}</strong>
              <div className="size"><span>{Fancy[innerAttr].BackSize1}</span></div>
            </div>
          </div>
        </span>

        {(( !Fancy[innerAttr].LayPrice1>0  && !Fancy[innerAttr].BackPrice1>0) || Fancy[innerAttr].isSuspended)?
        
        <div className="ball-running-msg">
        <h1>{Fancy[innerAttr].GameStatus}</h1>
      </div>
    
      :null}





      </div>
    </div>
    <div class="fancy-txt">{Fancy[innerAttr].message}</div>
     
  </div> 
:null} </>


:null} </>
 :null}
  </>
)


}) }


</div>

 
  </div>
</div> :null}






 </>
 );
};
  export default Odds