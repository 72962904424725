
import { React ,useContext,useState , useEffect} from "react"; 
import { AppStateContext }   from '../../../AppState';
import   Cricket  from './cricket'; 
import  Casino   from './casino'; 
const BetBoard = (props) => {
 
  const { state, updateState } = useContext(AppStateContext);
 
     

 
  
  return ( 
 

<>

    <div className={props.className} style={props.style}> 
 
   {state.BetData.Sport==="Casino"?Casino(): Cricket()}
     </div>

</>


  );
} 


export default BetBoard;
