import React, { useContext,useState } from "react";
import { Route, Switch } from "react-router-dom"; 

import Andar_Bahar_2 from "./Andar-Bahar-2/index";
import Amar_Akbar_Anthony from "./Amar-Akbar-Anthony/index"; 
import Dragon_Tiger from "./Dragon-Tiger/index";
import Dragon_Tiger_2 from "./Dragon-Tiger-2/index";
import Lucky_7_A from "./Lucky-7-A/index";
import Lucky_7_B from "./Lucky-7-B/index";
import TeenPatti_T20 from "./TeenPatti-T20/index";
import TeenPatti_ODI from "./TeenPatti-ODI/index";
import Aviator from "./Aviator/index";


const Live_Games = ({ match }) => {

    return (
        <Switch>
            
            <Route path={`${match.url}/ab2`} component={Andar_Bahar_2} />
            <Route path={`${match.url}/AAA`} component={Amar_Akbar_Anthony} />
            <Route path={`${match.url}/dt20`} component={Dragon_Tiger} />
            <Route path={`${match.url}/dt202`} component={Dragon_Tiger_2} />
            <Route path={`${match.url}/Lucky7A`} component={Lucky_7_A} />
            <Route path={`${match.url}/Lucky7B`} component={Lucky_7_B} />
            <Route path={`${match.url}/teenpattit20`} component={TeenPatti_T20} />
            <Route path={`${match.url}/teenpattiodi`} component={TeenPatti_ODI} />
            <Route path={`${match.url}/aviator`} component={Aviator} />
 
      </Switch>
    )

};

export default Live_Games;
