import React, { useContext,useState } from "react";
import { Route, Switch } from "react-router-dom";
import ChipsModel from "../../components/ChipsModel";
import BetBoard from "./BetBoard/index";
import Events from "./Events/index";
import Live_Games from "./Live-Games/index";
import Games from "./Games/index";
import { AppStateContext } from "../../AppState";
import MyBets from "../../components/MyBets";

const MainContent = ({ match }) => {
  const { state } = useContext(AppStateContext); 
     
  const [Tab, setTab] = useState("All");
  const handleChange = (type) => { 
  
   setTab(type);  
    
  }; 
  return (
    <>
      <main id="main" className="main-content"> 
        <div className="main-inner">
          <section className="match-content">
            <Switch>
            
              <Route path={`${match.url}/events/:eid`} component={Events} />


                 <Route path={`${match.url}/livegames`} component={Live_Games} />
        <Route path={`${match.url}`} component={Games} />
          
            </Switch>
          </section>
          <div id="betSidenav" className="betsidenav">
            <section className="right-bet-content">
              {/* <div>
                <div className="mod-header">
                  <div className="select-tv-ico">
                    <time>
                      <img src="https://urexch.in/assets/images/live-tv.png" />
                    </time>
                    <span>Live Tv 2</span>
                    <span style={{ float: "right" }}>
                      <i className="fas fa-chevron-down"></i>
                    </span>
                  </div>
                  <span id="close" className="cls-btn">
                    x
                  </span>
                </div>
              </div>
              <div className="collapse"></div>
*/}
              {state.BetData ? (
                <BetBoard className="betSlipBox" />
              ) : null}

            {/*  <div className="overlay_mobile in"></div>
              <div>
        <div className="mod-header">
      
      <div className="select-tv-ico">
        <span>BetSlip</span>   <ChipsModel ButtonType='button' />
   </div>
   </div>
   </div> */}
              <div className="right-bet-inner">
                <div
                  className="mod-header tab_bets betsheading"
                  style={{ display: "block" }}
                >
                  <ul className="nav nav-pills mb-1" id="pills-tab" role="tablist">
                    <li className="nav-item betdata active">
                      <a className="allbet" onClick={()=>{handleChange("All")}}>
                        <span className="bet-label">All Bet </span>
                        <span className="bat_counter"></span>
                      </a>
                    </li>
                    <li className="nav-item betdata">
                      <a className="matchbet" onClick={()=>{handleChange("Odds")}}>
                        <span className="bet-label">Match Bet</span>
                        <span className="bat_counter"></span>{" "}
                      </a>
                    </li>
                    <li className="nav-item betdata">
                      <a className="fancybet" onClick={()=>{handleChange("Session")}}>
                        <span className="bet-label">Fancy Bet</span>
                        <span className="bat_counter"></span>{" "}
                      </a>
                    </li>
                    <li className="nav-item betdata">
                      <a className="casinobet" onClick={()=>{handleChange("Casino")}}>
                        <span className="bet-label blinking">Casino Bet</span>
                        <span className="bat_counter"></span>{" "}
                      </a>
                    </li>
                    <li className="nav-item betdata">
                      <a className="matkabet" onClick={()=>{handleChange("Matka")}}>
                        <span className="bet-label blinking">Matka Bet</span>
                        <span className="bat_counter"></span>{" "}
                      </a>
                    </li>
                    <li className="nav-item full-screen"></li>
                  </ul>
                </div>
                <div id="MatchUnMatchBetaData">
                  <div id="accountView" role="main">
                    <span id="msg_error"></span>
                    <span id="errmsg"></span>
                    <div className="balance-panel-body">
                      <div className="table-responsive sports-tabel">
                        <Switch>
                          <Route
                            path={`${match.url}/events/:eid`}
                            render={() => <MyBets type={Tab} />}
                          />
                          <Route path={`/`}  render={() => <MyBets type={Tab} />} />
                        </Switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {state.BetData ? (
          <BetBoard
            className="betSlipBox mobile-bet-box"
            style={{ position: "fixed", bottom: "0px", zIndex: "999" }}
          />
        ) : null}
      </main>
    </>
  );
};

export default MainContent;
