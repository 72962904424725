import React, { useContext,useEffect,useState } from "react";   
import {io} from 'socket.io-client'; 

import { AppStateContext }   from '../../../../AppState';
const Amar_Akbar_Anthony = ({ match }) => { 
    const { state, updateState } = useContext(AppStateContext);


   const [CircleCards, setCircleCards] = useState({});
  const [ws, setWs] = useState(null);


  const  circleCode  = "aaa";
  const findPropertyValueById=(array, id, property)=> {
     const foundObject = array.find(obj => obj.nat === id);
     return foundObject ? foundObject[property] : undefined;
   }

  useEffect(() => {
     const createWebSocket = () => {
       const circleKey="aaa";
  
     const ioClient = io.connect('wss://circle-data.yply.xyz:4026', { 
      rememberUpgrade:true,
      transports: ['websocket'],
      secure:true, 
      rejectUnauthorized: false,
       withCredentials: true,
              transports: ['websocket'], reconnection: true,
              reconnectionDelay: 1000,
              reconnectionDelayMax: 5000,
              reconnectionAttempts: 999999
          });
         
       
          ioClient.emit('casino', circleKey);
        
          ioClient.io.on('reconnect', function() {
            ioClient.emit('event', eid);
})



// General error handling
ioClient.on('error', (err) => {
   console.error('Socket error:', err);
 });
 
 // Handle connection errors
 ioClient.on('connect_error', (err) => {
   console.error('Connection error:', err);
 });
 
 // Handle connection timeouts
 ioClient.on('connect_timeout', () => {
   console.error('Connection timeout');
 });
 
 // Handle disconnection
 ioClient.on('disconnect', (reason) => {
   console.warn('Disconnected:', reason);
   if (reason === 'io server disconnect') {
     // The disconnection was initiated by the server, you can attempt to reconnect
     ioClient.connect();
   }
 });




ioClient.on('casino/'+circleKey , function(data){

    const newMessage = data;
              const Cards = { Main: newMessage.t1[0].C1,   Data:  data  };
      
          setCircleCards(Cards);

});
      
         
      
        return ioClient;
      };
      
      let reconnectAttempts = 0;
      const maxReconnectAttempts = 10;
      
      const reconnect = () => {
        if (reconnectAttempts < maxReconnectAttempts) {
          const timeout = Math.min(1000 * Math.pow(2, reconnectAttempts), 30000); // Exponential backoff with a cap
          setTimeout(() => {
            console.log(`Reconnecting... Attempt ${reconnectAttempts + 1}`);
            const webSocket = createWebSocket();
            setWs(webSocket);
            reconnectAttempts++;
          }, timeout);
        } else {
          console.log('Max reconnection attempts reached');
        }
      };
      
      // Initial WebSocket connection
      let webSocket = createWebSocket();
      setWs(webSocket);
      
      // Cleanup function
      return () => {
        webSocket.close();
      };
      
  }, []);
  const authUser =JSON.parse(localStorage.getItem('authUser'));

  const OpenBetBoard= async(data)=>{
 
     
     updateState({ BetData:data });
    updateState({ BetAmount:0 }); 
 
  } 
return (
    <>
    <div class="ab2-main casino">
    <div class="match-box">
       <div class="mod-header">
          <div class="select-tv-ico"><span>Amar Akbar Anthony</span><span style={{"float": "right"}}>{CircleCards.Data?"Round Id : "+CircleCards.Data.round_id:0}</span></div>
       </div>
    </div>
    <div>
       <div class="col-12 col-md-12" style={{"padding": "0px"}}>
          <iframe class="live-str" src={`https://tv.yply.xyz/play.html?id=${circleCode}`} height="400" width="100%" scrolling="auto" style={{"background-color": "black"}}></iframe>
          <button class="rules-btn">
           <img style={{"width": "20px"}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABMlBMVEUAAAAAgJIJfo8If5EIgJAIgJAIgJAIgJAJgJEAdokHgI0If5EIf5AIgJAHfJEAcY4HgJAIf5AHgJAAgJ8If5AIf5AKeo8KfZIIf5AGgJALgJUIf5AIfpEKfZEJfpAIgJAAbZIIgJAHfo8If5AIf5AAgIAFfZIIf5AHfo4JgJEJgJEIgJAIfpEKgI8Hf5AAgJUJfpAIgJAIgJAIf48If5AHgJALepAOgI4IgJAIgJAIf5AJgJEIfpAHgJEIgJAIf5AIf5AIfpAAgI8If5EIf5AJgJEMeZIHf48IgJAIgI8If5AIgJAAgIAJfpAIgJAGgJEHf5EIgJAIgI8IgI8IgJAAgIAIf5AIgJAIfY4IgJEIf5AIf5AIgJAIf5AJe44GfY4If5AIgJAIf5AJgJEIf5AAAADnHCSFAAAAZHRSTlMADlmbyur6mlgNJp31nCUJjPuKCNPHGTHjLhjnXzNx9Ac+aYH9Bi/zTXiW8n1QjwxX2PiZ1WwXEqbI63ZjSO7p+f4Qyd/SFYtcRKOYAlW4LI324CLcBIfUPYbhxeLlGy3BxpdWz+XyAwAAAAFiS0dEAIgFHUgAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQflAhMFDybhb9BZAAABZElEQVQ4y32T2VrCQAyFI5QCsgqiUNSioCgqILiBaIsLLogbbijuff9nMK06TaCfuTs5/0zSaQIgYsTlljyy7JG8Pj8Mx2ggaIgIhsIDdiQ6ZrCQY3Hqh8eNoUhM2P6kx3CIZEqcd/SRUH7r0/vTU9MzqqiSsYAoPTWLiTmhstb30f5z85hZEDJvFgmwuouYWbJlAd8vyIDlldUiuVItgcv4N8rgZjq9VuFAFSRbrG9sAmxtM6AGdVvsNHaxxfgeBTTQqWzuI3FAMxUO5CIIHHKgTuUR+q0mLyFReYzACWsyAV4qTxE4Y0AbfESp+PfONQZ0wE+e+gIvuGQ+PjWEbHmFQIcBXnOeZCGL5vjlr29EQu+aAxET+tbahzu7zXtrouIJkXhoPHZ6T0I+/4wcKH3DMV5e/8Y6lXTy+2/2YrTeh/0Pha5WJqtyW+9lBrZTKRBErXYd9rtUbtc0Xddqn18lO/sNxUwSHR4NB6YAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDItMTlUMDU6MTU6MzgrMDA6MDCyNS9NAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTAyLTE5VDA1OjE1OjM4KzAwOjAww2iX8QAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII="/></button>
           <div class="flipclock"><span class="flipclock-t1 fold">{CircleCards.Data?CircleCards.Data.t1[0].autotime.padStart(2, '0').replace(/^(0)(\d)/, '$1 $2') :"0 0"}</span></div>
           <div class="res-box">
          <img src={"https://urexch.in/cards/"+(CircleCards?CircleCards.Main:1)+".png"} class="ab2-res-fst-card"/>
           
          </div>
       </div>
    </div>
    <div class="row mr-left-right lucky7-main" style={{"width": "100%"}}>
   <div class="col-4 col-md-4 lucky7-main-bhav" style={{"padding-right": "5px", "padding-left": "0px"}}>
      <span class="col-12 col-md-12 casino-profit-text">A.Amar</span>


      {CircleCards.Data&&(CircleCards.Data.t2[0].gstatus==="0"||CircleCards.Data.t2[0].gstatus==="CLOSED"||CircleCards.Data.t2[0].gstatus==="SUSPENDED")?
  
      <span class="col-12 col-md-12 aaa-bhav" style={{"padding": "0px"}}>
         
         <div class="col-12 col-md-12 lucky7-bhav back"  style={{"padding": "0px"}}>
            <span>
               <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
               0.00
            </span>
         </div>
      </span>:
   
   <span class="col-12 col-md-12 aaa-bhav" style={{"padding": "0px"}}>
         
   <div class="col-12 col-md-12 lucky7-bhav back"  onClick={async()=>{
               
               OpenBetBoard({
                  Value:"1.12",
                  Type:"AMAR", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"Amar",
                  SelectionId:CircleCards.Data.t2[0].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"  
                 })
              }
               } 
               
               
               style={{"padding": "0px"}}>
      <span>
         1.12
      </span>
   </div>
</span>
   
   
   }
      <span class="col-12 col-md-12 casino-profit-text">0</span>
   </div>
   <div class="col-4 col-md-4 lucky7-main-bhav" style={{"padding-right": "5px", "padding-left": "0px"}}>
      <span class="col-12 col-md-12 casino-profit-text">B.Akbar</span>


      {CircleCards.Data&&(CircleCards.Data.t2[1].gstatus==="0"||CircleCards.Data.t2[1].gstatus==="CLOSED"||CircleCards.Data.t2[1].gstatus==="SUSPENDED")?
  
      <span class="col-12 col-md-12 aaa-bhav" style={{"padding": "0px"}}>
         
         <div class="col-12 col-md-12 lucky7-bhav back"  style={{"padding": "0px"}}>
            <span>
               <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
               0.00
            </span>
         </div>
      </span>:
   
   <span class="col-12 col-md-12 aaa-bhav" style={{"padding": "0px"}}>
         
   <div class="col-12 col-md-12 lucky7-bhav back"  onClick={async()=>{
               
               OpenBetBoard({
                  Value:"2.15",
                  Type:"AKBAR", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"Akbar",
                  SelectionId:CircleCards.Data.t2[1].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"  
                 })
              }
               } 
               
               
               style={{"padding": "0px"}}>
      <span>
      2.15
      </span>
   </div>
</span>
   
   
   }
      <span class="col-12 col-md-12 casino-profit-text">0</span>
   </div>
   <div class="col-4 col-md-4 lucky7-main-bhav" style={{"padding-right": "5px", "padding-left": "0px"}}>
      <span class="col-12 col-md-12 casino-profit-text">C.Anthony</span>


      {CircleCards.Data&&(CircleCards.Data.t2[2].gstatus==="0"||CircleCards.Data.t2[2].gstatus==="CLOSED"||CircleCards.Data.t2[2].gstatus==="SUSPENDED")?
  
      <span class="col-12 col-md-12 aaa-bhav" style={{"padding": "0px"}}>
         
         <div class="col-12 col-md-12 lucky7-bhav back"  style={{"padding": "0px"}}>
            <span>
               <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
               0.00
            </span>
         </div>
      </span>:
   
   <span class="col-12 col-md-12 aaa-bhav" style={{"padding": "0px"}}>
         
   <div class="col-12 col-md-12 lucky7-bhav back"  onClick={async()=>{
               
               OpenBetBoard({
                  Value:"3.15",
                  Type:"ANTHONY", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"Anthony",
                  SelectionId:CircleCards.Data.t2[2].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"  
                 })
              }
               } 
               
               
               style={{"padding": "0px"}}>
      <span>
      3.15
      </span>
   </div>
</span>
   
   
   }
      <span class="col-12 col-md-12 casino-profit-text">0</span>
   </div>
 
</div>
<div class="row mr-left-right lucky7-main">
   <div class="col-6 col-md-6 lucky7-main-bhav">
      <span class="col-12 col-md-12 casino-profit-text">1.12</span>
      {CircleCards.Data&&(CircleCards.Data.t2[3].gstatus==="0"||CircleCards.Data.t2[3].gstatus==="CLOSED"||CircleCards.Data.t2[3].gstatus==="SUSPENDED")?
      
       <div class="col-12 col-md-12 lucky7-bhav"style={{"padding": "0px"}}>
         <span>
            <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
            EVEN
         </span>
      </div>:
      
      <div class="col-12 col-md-12 lucky7-bhav"
         style={{"padding": "0px"}}>
         <span>
         <button  onClick={async()=>{
               
         OpenBetBoard({
            Value:"1.12",
            Type:"EVEN", 
            Round:CircleCards.Data.round_id, 
            Game:"AAA", 
            U_ID:authUser.ID, 
            Id:"Even",
              SelectionId:CircleCards.Data.t2[6].sid,
             Size:0,
              Sport:"Casino",
                   GType:"Amar Akbar Anthony"  
            
          })
        }
         }>EVEN</button>  
         </span>
      </div>
      
      }
      <span class="col-12 col-md-12 casino-profit-text">0</span>
      
      <span class="col-12 col-md-12 casino-profit-text">0.83</span>
      {CircleCards.Data&&(CircleCards.Data.t2[4].gstatus==="0"||CircleCards.Data.t2[4].gstatus==="CLOSED"||CircleCards.Data.t2[4].gstatus==="SUSPENDED")?
      
      <div class="col-12 col-md-12 lucky7-bhav"style={{"padding": "0px"}}>
        <span>
           <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
           ODD
        </span>
     </div>:
     
     <div class="col-12 col-md-12 lucky7-bhav"  
        style={{"padding": "0px"}}>
        <span>
        <button onClick={async()=>{
              
        OpenBetBoard({
           Value:"0.83",
           Type:"ODD", 
           Round:CircleCards.Data.round_id, 
           Game:"AAA", 
           U_ID:authUser.ID, 
           Id:"Odd",
             SelectionId:CircleCards.Data.t2[4].sid,
            Size:0,
             Sport:"Casino",
                   GType:"Amar Akbar Anthony"  
           
         })
       }
        }>ODD</button>
        </span>
     </div>
     
     }
      <span class="col-12 col-md-12 casino-profit-text">0</span>
   </div>
   <div class="col-6 col-md-6 lucky7-main-bhav">
      <span class="col-12 col-md-12 casino-profit-text">0.97</span>
      {CircleCards.Data&&(CircleCards.Data.t2[6].gstatus==="0"||CircleCards.Data.t2[6].gstatus==="CLOSED"||CircleCards.Data.t2[6].gstatus==="SUSPENDED")?
     <div class="col-12 col-md-12 lucky7-bhav"style={{"padding": "0px"}}>
         <span>
            <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
            <img class="tpatti-img-height" src="https://urexch.in/cards/heart.png"/><img class="tpatti-img-height" src="https://urexch.in/cards/diamond.png"/>
         </span>
      </div>

:
<div class="col-12 col-md-12 lucky7-bhav"  onClick={async()=>{
              
              OpenBetBoard({
                 Value:"0.97",
                 Type:"RED", 
                 Round:CircleCards.Data.round_id, 
                 Game:"AAA", 
                 U_ID:authUser.ID, 
                 Id:"Red",
                   SelectionId:CircleCards.Data.t2[6].sid,
                  Size:0,
                   Sport:"Casino",
                   GType:"Amar Akbar Anthony"  
                 
               })
             }
              } style={{"padding": "0px"}}>
<span>
   <img class="tpatti-img-height" src="https://urexch.in/cards/heart.png"/><img class="tpatti-img-height" src="https://urexch.in/cards/diamond.png"/>
</span>
</div>
}


      <span class="col-12 col-md-12 casino-profit-text">0</span>
      
      <span class="col-12 col-md-12 casino-profit-text">0.97</span>
      {CircleCards.Data&&(CircleCards.Data.t2[7].gstatus==="0"||CircleCards.Data.t2[7].gstatus==="CLOSED"||CircleCards.Data.t2[7].gstatus==="SUSPENDED")?
     
      <div class="col-12 col-md-12 lucky7-bhav" style={{"padding": "0px"}}>
         <span>
            <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
            <img class="tpatti-img-height" src="https://urexch.in/cards/ace-of-spades.png"/><img class="tpatti-img-height" src="https://urexch.in/cards/clover-ace.png"/>
         </span>
      </div>:
      
      
      <div class="col-12 col-md-12 lucky7-bhav" style={{"padding": "0px"}} onClick={async()=>{
              
         OpenBetBoard({
            Value:"0.97",
            Type:"BLACK", 
            Round:CircleCards.Data.round_id, 
            Game:"AAA", 
            U_ID:authUser.ID, 
            Id:"Black",
              SelectionId:CircleCards.Data.t2[7].sid,
             Size:0,
              Sport:"Casino",
                   GType:"Amar Akbar Anthony"  
            
          })
        }
         }>
         <span>
            <img class="tpatti-img-height" src="https://urexch.in/cards/ace-of-spades.png"/><img class="tpatti-img-height" src="https://urexch.in/cards/clover-ace.png"/>
         </span>
      </div>
      
      }
      <span class="col-12 col-md-12 casino-profit-text">0</span>
   </div>
</div>
   

<div class="row mr-left-right lucky7-main">
        <div class="col-12 col-md-12 lucky7-main-bhav"><span style={{"color": "rgb(0, 0, 0)", "font-weight": "700"}}>11.00</span></div>
        <div class="row mr-left-right" style={{"width": "100%"}}>
           <div class="col-1 lucky7-main-bhav-card"  style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[7].gstatus==="0"||CircleCards.Data.t2[7].gstatus==="CLOSED"||CircleCards.Data.t2[7].gstatus==="SUSPENDED")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img  onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"1", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"A",
                    SelectionId:CircleCards.Data.t2[7].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"
                 })
              }
               }src="https://urexch.in/cards/1.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[8].gstatus==="0"||CircleCards.Data.t2[8].gstatus==="CLOSED"||CircleCards.Data.t2[8].gstatus==="SUSPENDED")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img  onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"2", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"2",
                    SelectionId:CircleCards.Data.t2[8].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"
                 })
              }
               }src="https://urexch.in/cards/2.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[9].gstatus==="0"||CircleCards.Data.t2[9].gstatus==="CLOSED"||CircleCards.Data.t2[9].gstatus==="SUSPENDED")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img  onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"3", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"3",
                    SelectionId:CircleCards.Data.t2[9].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"
                 })
              }
               }src="https://urexch.in/cards/3.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[10].gstatus==="0"||CircleCards.Data.t2[10].gstatus==="CLOSED"||CircleCards.Data.t2[10].gstatus==="SUSPENDED")?<div class="susb-bhav"><img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"4", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"4",
                    SelectionId:CircleCards.Data.t2[10].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"
                 })
              }
               }src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img src="https://urexch.in/cards/4.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[11].gstatus==="0"||CircleCards.Data.t2[11].gstatus==="CLOSED"||CircleCards.Data.t2[11].gstatus==="SUSPENDED")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"5", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"5",
                    SelectionId:CircleCards.Data.t2[11].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"
                 })
              }
               }src="https://urexch.in/cards/5.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[12].gstatus==="0"||CircleCards.Data.t2[12].gstatus==="CLOSED"||CircleCards.Data.t2[12].gstatus==="SUSPENDED")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"6", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"6",
                    SelectionId:CircleCards.Data.t2[12].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"
                 })
              }
               }src="https://urexch.in/cards/6.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[13].gstatus==="0"||CircleCards.Data.t2[13].gstatus==="CLOSED"||CircleCards.Data.t2[13].gstatus==="SUSPENDED")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"7", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"7",
                    SelectionId:CircleCards.Data.t2[13].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"
                 })
              }
               }src="https://urexch.in/cards/7.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[14].gstatus==="0"||CircleCards.Data.t2[14].gstatus==="CLOSED"||CircleCards.Data.t2[14].gstatus==="SUSPENDED")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"8", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"8",
                    SelectionId:CircleCards.Data.t2[14].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"
                 })
              }
               } src="https://urexch.in/cards/8.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[15].gstatus==="0"||CircleCards.Data.t2[15].gstatus==="CLOSED"||CircleCards.Data.t2[15].gstatus==="SUSPENDED")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"9", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"9",
                    SelectionId:CircleCards.Data.t2[15].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"
                 })
              }
               }src="https://urexch.in/cards/9.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[16].gstatus==="0"||CircleCards.Data.t2[16].gstatus==="CLOSED"||CircleCards.Data.t2[16].gstatus==="SUSPENDED")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"10", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"10",
                    SelectionId:CircleCards.Data.t2[16].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"
                 })
              }
               }src="https://urexch.in/cards/10.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[17].gstatus==="0"||CircleCards.Data.t2[17].gstatus==="CLOSED"||CircleCards.Data.t2[17].gstatus==="SUSPENDED")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"11", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"J",
                    SelectionId:CircleCards.Data.t2[17].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"
                 })
              }
               }src="https://urexch.in/cards/11.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[18].gstatus==="0"||CircleCards.Data.t2[18].gstatus==="CLOSED"||CircleCards.Data.t2[18].gstatus==="SUSPENDED")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"12", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"Q",
                    SelectionId:CircleCards.Data.t2[18].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"
                 })
              }
               }src="https://urexch.in/cards/12.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[19].gstatus==="0"||CircleCards.Data.t2[19].gstatus==="CLOSED"||CircleCards.Data.t2[19].gstatus==="SUSPENDED")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"13", 
                  Round:CircleCards.Data.round_id, 
                  Game:"AAA", 
                  U_ID:authUser.ID, 
                  Id:"K",
                    SelectionId:CircleCards.Data.t2[19].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Amar Akbar Anthony"
                 })
              }
               } src="https://urexch.in/cards/13.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
        </div>
     </div>
    <div style={{"margin-left": "0px", "margin-right": "0px"}} class="row">
       <div class="match-box">
          <div class="mod-header">
             <div class="select-tv-ico"><span>Last Result</span><span style={{"float": "right"}}><a style={{"color": "rgb(255, 255, 255)"}} href="/account/ac-statement">View All</a></span></div>
          </div>
       </div>
       <div class="Result" style={{"color": "white", "background-color": "rgb(255, 255, 255)", "text-align": "right", "width": "100%"}}>
           <span class="game-result playera">A</span>
           <span class="game-result playera">A</span>
           <span class="game-result playerb">B</span>
           <span class="game-result playera">A</span>
           <span class="game-result playerb">B</span>
           <span class="game-result playera">A</span>
           <span class="game-result playerb">B</span>
           <span class="game-result playerb">B</span>
           <span class="game-result playerb">B</span>
           <span class="game-result playerb">B</span>
           </div>
    </div>
    </div>
  

 </>
   )


};

export default Amar_Akbar_Anthony;
