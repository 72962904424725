import { React, useEffect, useState } from 'react';
import { getLedger } from '../../../util/rex'; 
 
const Ledger = () => {
      const [ Ledger, setLedger ] = useState([]);
   

    useEffect(() => {
        const fetchData = async () => {
 const _ledger=await getLedger() ;
         setLedger(_ledger);  
         };

        fetchData();
    }, []);

    return (
        <div className="main-content">
        <div className="main-inner">
           <section className="accountste match-content">
              <div className="table_tittle">My Leadger</div>
              <div className="col-md-12"></div>
              <div id="divLoading"> </div>
              <div className="appendAjaxTbl">
                 <div className="table-responsive sports-tabel">
                    <table className="table table-bordered table-hover" id="datatabless">
                       <thead>
                          <tr >
                             <th className="">S.No. </th>
                             <th className="">Description </th>
                             <th className="rrig text-left">WON BY </th>
                             <th className="rrig text-left">WON</th>
                             <th className="rrig text-left">LOST </th>
                             <th className="rrig text-left">HISAB </th>
                          </tr>
                       </thead>
                       <tbody>
                       {Ledger ? Ledger.map((item, index) => (
                                     <tr  key={item.S_No} className="heading_user_table">
                             <td>{item.S_No}</td>
                             <td className=""><a href={'/account/my-ledger/match/'+item.Event_ID}>{item.Description?item.Description:'N/A'}</a></td>
                             <td className=" text-left">{item.Winner?item.Winner:'N/A'} </td>
                             <td className=" text-left">{parseFloat(item.WON).toFixed(2) }</td>
                             <td className=" text-left">{parseFloat(item.LOSS).toFixed(2)} </td>
                             <td className=" text-left">{parseFloat(item.HISAB).toFixed(2)} </td>
                          </tr>
                          )) : null}
                       </tbody>
                    </table>
                 </div>
              </div>
           </section>
        </div>
     </div>
    );
};

export default Ledger;