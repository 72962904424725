
import { React ,useContext,useState , useEffect} from "react";
 
import  TextInput from '../../../components/TextInput';
import {createBet  }   from '../../../util/rex';
import { AppStateContext }   from '../../../AppState';
import { getUser } from '../../../App/Signin/Auth';
import { json } from "react-router";
const Casino = () => {
   const [seconds, setSeconds] = useState(8);
   const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { state, updateState } = useContext(AppStateContext);
  const [IsLoading, setIsLoading] = useState(false);
  useEffect(() => {
   const interval = setInterval(() => {
     setSeconds(prevSeconds => prevSeconds - 1);
   }, 1000);

   return () => clearInterval(interval);
 }, []);
 useEffect(() => {
   if (seconds === 0) {
      setSeconds(0);
  
     updateState({ BetData:false });
     console.log('Timer reached 0 seconds!');
   }
 }, [seconds]);
 //alert(JSON.stringify(state.BetData));
  const handleChange = (event) => { 

   let inputValue = event.target.value;

  // Remove leading zeros if the input is a number
  if (/^0[0-9]+$/.test(inputValue)) {
    inputValue = inputValue.replace(/^0+/, '');
  }

     updateState({ BetAmount:inputValue });  
     
    
 };
  const handleIncrease1 = () => {
    if (state.BetAmount < 10) {
        updateState({ BetAmount:10 });  
    }else{  
        updateState({ BetAmount:state.BetAmount+1 });  
    
    }
  };

  const handleDecrease1 = () => {
    if (state.BetAmount > 10) {
        updateState({ BetAmount:state.BetAmount-1 });   
    }
  };
 

  const handleSubmit = (e) => { 

      e.preventDefault();  
       setIsButtonDisabled(true);
        setIsLoading(true);

       
let Bt_Data=state.BetData;
Bt_Data.Stake=state.BetAmount ;
Bt_Data.Chips=state.authUser.Chips; 
Bt_Data.Block_Betting=state.authUser.Block_Betting; 
 



 createBet(Bt_Data).then(async ress=>{

         updateState( await getUser());
   
  
   updateState({ BetData:false }) 
   
   setIsButtonDisabled(false);
    setIsLoading(false); 



  }).catch(function (error) {
   setIsButtonDisabled(false);
   updateState({ BetData:false }) 
   setIsLoading(false);  

  }); 

    
 

    
  }

 
  
  return ( 
 

<> 
 
    <div className="betBox bet-slip-box">
           <span id="msg_error"></span><span id="errmsg"></span>
           <div className="lds-dual-ring  loader" style={{"display": "none"}}></div>
           <div className="bet-box_inner" style={{"background-color": "rgb(250, 169, 186)"}}>
              <div style={{"display": "flex", "justify-content": "space-between", "padding": "10px", "margin": "5px", "align-items": "center"}}>
                 <div style={{"font-weight": "700"}}>{ state.BetData.Id }</div>
                 <div style={{"background-color": "rgb(210, 50, 45)","color":"#fff","border":"1px,solid,#0000","border-radius":"10px", "font-weight": "700", "padding": "5px"}}>⏱︎| {seconds}</div>
              </div>
              <div className="inner-bet-section">

              <form className="m-0 " onSubmit={handleSubmit}>
    
   
                 <div className="oddds-stake-box" style={{"margin-bottom": "10px"}}>
                    <div className="items odds">
                       <div className="stack_input_field numbers-row">
                          <TextInput disabled type="number" id="rateValue" className="disabled  form-control " value={ state.BetData.Value }/>
                          <div className="inc plus_btn" >
                             <div className="quantity-button quantity-up"><i className="fas fa-plus"></i></div>
                          </div>
                          <div className="dec plus_btn">
                             <div className="quantity-button quantity-down"><i className="fas fa-minus"></i></div>
                          </div>
                       </div>
                    </div>
                    <div className="items stake" id=" ">
                       <div className="stack_input_field numbers-row">
                          <input type="number" onChange={handleChange}  onMouseLeave={() => { if (!state.BetAmount) { handleChange({ target: { value: 0 } });}
      
  }}  pattern="[0-9]*" step="1" id="stakeValue" className="calProfitLoss stake-input form-control  CommanBtn" value={state.BetAmount}/>
                          <div className="inc plus_btn" onClick={handleIncrease1}>
                              <div className="quantity-button quantity-up"><i className="fas fa-plus"></i></div>
                          </div>
                          <div className="dec plus_btn" onClick={handleDecrease1}>
                             <div className="quantity-button quantity-down"><i className="fas fa-minus"></i></div>
                          </div>
                       </div>
                    </div>
                 </div>
              



                 <div className="bet-box-footer">
                    <button onClick={  ()=>{updateState({ BetAmount:0 })}}  className="btn cancle-bet" type="button">Reset Bet </button>
                 <button className="btn place-bet"  disabled={isButtonDisabled} type="submit">{IsLoading? 
                 <div role="status" class="spinner-border-sm spinner-border text-light"><span class="sr-only">Loading...</span></div>
                :"Place Bet"}
                 
                 </button>
                 </div> 
            
  </form>
           
           
           
           
           
           
           
              </div>
           </div>
        </div>
    

</>


  );
} 


export default Casino;
