import React from "react";
import {Route, Switch} from "react-router-dom";
 
import Statement from "./ac-statement/index"; 
import Match from "./my-ledger/Match/index"; 
import Ledger from "./my-ledger/index"; 
const Account = ({match}) => (
     <Switch>  
    <Route path={`${match.url}/ac-statement`} component={Statement}/>   
    <Route path={`${match.url}/my-ledger/match/:eid`} component={Match}/>   
 
    <Route path={`${match.url}/my-ledger`} component={Ledger}/>   
    </Switch> 
);

export default Account;
