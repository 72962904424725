import React, { useContext,useEffect,useState } from "react";   
import {io} from 'socket.io-client'; 

import { AppStateContext }   from '../../../../AppState'; 
  
const Andar_Bahar_2 = ({ match }) => {  
 const { state, updateState } = useContext(AppStateContext);

    const [CircleCards, setCircleCards] = useState({});


   const [ws, setWs] = useState(null);

   
  const  circleCode  = "ab2";
   const findPropertyValueById=(array, id, property)=> {
      const foundObject = array.find(obj => obj.nat === id);
      return foundObject ? foundObject[property] : undefined;
    }
   useEffect(() => {
     
 const createWebSocket = () => {
   const circleKey="abj";
  
     const ioClient = io.connect('wss://circle-data.yply.xyz:4026', { 
      rememberUpgrade:true,
      transports: ['websocket'],
      secure:true, 
      rejectUnauthorized: false,
       withCredentials: true,
              transports: ['websocket'], reconnection: true,
              reconnectionDelay: 1000,
              reconnectionDelayMax: 5000,
              reconnectionAttempts: 999999
          });
         
       
          ioClient.emit('casino', circleKey);
        
          ioClient.io.on('reconnect', function() {
            ioClient.emit(casino, circleKey);
})



// General error handling
ioClient.on('error', (err) => {
   console.error('Socket error:', err);
 });
 
 // Handle connection errors
 ioClient.on('connect_error', (err) => {
   console.error('Connection error:', err);
 });
 
 // Handle connection timeouts
 ioClient.on('connect_timeout', () => {
   console.error('Connection timeout');
 });
 
 // Handle disconnection
 ioClient.on('disconnect', (reason) => {
   console.warn('Disconnected:', reason);
   if (reason === 'io server disconnect') {
     // The disconnection was initiated by the server, you can attempt to reconnect
     ioClient.connect();
   }
 });




ioClient.on('casino/'+circleKey  , function(data){
            
            
           
            const newMessage = data;
            const cardsArray = newMessage.t1[0].cards.split(',');
            const Cards = { Main: cardsArray[0], Andar: null, Bahar: null, Data: data  };
        
            const andarCards = [];
            const baharCards = [];
        
            // Loop through each card, starting from the second one
            cardsArray.slice(1).forEach((card, index) => {
              if (card != "1") {
                if (index % 2 === 0) {
                  baharCards.push(card);
                } else {
                  andarCards.push(card);
                }
              }
            });
        
            Cards.Andar = (andarCards.length > 0 ? andarCards : null);
            Cards.Bahar = (baharCards.length > 0 ? baharCards : null);
        
            setCircleCards(Cards);
  
  


         })


















         return ioClient;
       };
       
       
       
       // Initial WebSocket connection
       let webSocket = createWebSocket();
       setWs(webSocket);
       
       // Cleanup function
       return () => {
         webSocket.close();
       };
       
   }, []);
   const authUser =JSON.parse(localStorage.getItem('authUser'));

   const OpenBetBoard= async(data)=>{
  
      
      updateState({ BetData:data });
     updateState({ BetAmount:0 }); 
  
   } 
    return (
    
     <>  
     <div class="ab2-main casino">
     <div class="match-box">
        <div class="mod-header">
           <div class="select-tv-ico"><span>Andar Bahar 2</span><span style={{"float": "right"}}>{CircleCards.Data?"Round Id : "+CircleCards.Data.round_id:0}</span></div>
           </div>
     </div>
     <div>
        <div class="col-12 col-md-12" style={{"padding": "0px"}}>
            <iframe class="live-str" src={`https://tv.yply.xyz/play.html?id=${circleCode}`} height="400" width="100%" scrolling="auto" style={{"background-color": "black"}}></iframe>  
           <button class="rules-btn">
            <img style={{"width": "20px"}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABMlBMVEUAAAAAgJIJfo8If5EIgJAIgJAIgJAIgJAJgJEAdokHgI0If5EIf5AIgJAHfJEAcY4HgJAIf5AHgJAAgJ8If5AIf5AKeo8KfZIIf5AGgJALgJUIf5AIfpEKfZEJfpAIgJAAbZIIgJAHfo8If5AIf5AAgIAFfZIIf5AHfo4JgJEJgJEIgJAIfpEKgI8Hf5AAgJUJfpAIgJAIgJAIf48If5AHgJALepAOgI4IgJAIgJAIf5AJgJEIfpAHgJEIgJAIf5AIf5AIfpAAgI8If5EIf5AJgJEMeZIHf48IgJAIgI8If5AIgJAAgIAJfpAIgJAGgJEHf5EIgJAIgI8IgI8IgJAAgIAIf5AIgJAIfY4IgJEIf5AIf5AIgJAIf5AJe44GfY4If5AIgJAIf5AJgJEIf5AAAADnHCSFAAAAZHRSTlMADlmbyur6mlgNJp31nCUJjPuKCNPHGTHjLhjnXzNx9Ac+aYH9Bi/zTXiW8n1QjwxX2PiZ1WwXEqbI63ZjSO7p+f4Qyd/SFYtcRKOYAlW4LI324CLcBIfUPYbhxeLlGy3BxpdWz+XyAwAAAAFiS0dEAIgFHUgAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQflAhMFDybhb9BZAAABZElEQVQ4y32T2VrCQAyFI5QCsgqiUNSioCgqILiBaIsLLogbbijuff9nMK06TaCfuTs5/0zSaQIgYsTlljyy7JG8Pj8Mx2ggaIgIhsIDdiQ6ZrCQY3Hqh8eNoUhM2P6kx3CIZEqcd/SRUH7r0/vTU9MzqqiSsYAoPTWLiTmhstb30f5z85hZEDJvFgmwuouYWbJlAd8vyIDlldUiuVItgcv4N8rgZjq9VuFAFSRbrG9sAmxtM6AGdVvsNHaxxfgeBTTQqWzuI3FAMxUO5CIIHHKgTuUR+q0mLyFReYzACWsyAV4qTxE4Y0AbfESp+PfONQZ0wE+e+gIvuGQ+PjWEbHmFQIcBXnOeZCGL5vjlr29EQu+aAxET+tbahzu7zXtrouIJkXhoPHZ6T0I+/4wcKH3DMV5e/8Y6lXTy+2/2YrTeh/0Pha5WJqtyW+9lBrZTKRBErXYd9rtUbtc0Xddqn18lO/sNxUwSHR4NB6YAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDItMTlUMDU6MTU6MzgrMDA6MDCyNS9NAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTAyLTE5VDA1OjE1OjM4KzAwOjAww2iX8QAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII="/></button>
           <div class="flipclock"><span class="flipclock-t1 fold">{CircleCards.Data?CircleCards.Data.t1[0].autotime.padStart(2, '0').replace(/^(0)(\d)/, '$1 $2') :"0 0"}</span></div>
           <div class="res-box">
              <img src={"https://urexch.in/cards/"+(CircleCards?CircleCards.Main:1)+".png"} class="ab2-res-fst-card"/>
           
              {CircleCards  && CircleCards.Andar ?
         <>
              <div class="col-md-12 col-12 andr-bhar2-res-text andarbahar-res">
                 Andar<span class="next-card-res"><img src="https://urexch.in/right-arrow.png" style={{"height": "15px"}}/></span><span class="perv-card-res">
                    <img src="https://urexch.in/left-arrow.png" style={{"height": "15px"}}/></span>
               <div class="rescard andar andar1">  
               {CircleCards.Andar.map((card, index) => (

<img  key={index} src={`https://urexch.in/cards/${card}.png`} alt={card} class="andar-bahar-image"/> 
        ))}
 
                 </div>
              </div> </> : null 
             }
                {CircleCards  && CircleCards.Bahar ?
      
        
             <> <div class="col-md-12 col-12 andr-bhar2-res-text andarbahar-res">
                 Bahar<span class="next-card-res">
                  <img src="https://urexch.in/right-arrow.png" style={{"height": "15px"}}/></span><span class="perv-card-res">
                    <img src="https://urexch.in/left-arrow.png" style={{"height": "15px"}}/></span>
                 <div class="rescard  bahar bahar1">
                 {CircleCards.Bahar.map((card, index) => (

<img  key={index} src={`https://urexch.in/cards/${card}.png`} alt={card} class="andar-bahar-image"/> 
        ))}
                 </div>
              </div> </> :  null }
             
           </div>
        </div>
     </div>
     <div class="row mr-left-right lucky7-main" style={{"justify-content": "center"}}>
        <div style={{"text-align": "center"}} class="col-12 col-sm-2 col-md-2">
           <div class="ab2-text" /><strong style={{"color": "rgb(0, 0, 0)", "font-weight": "700"}}>Andar</strong></div>
        <div class="col-3 col-sm-3 col-md-3">
              {CircleCards.Data&&(CircleCards.Data.t2[0].gstatus==="0")?
              <div class="col-12 ab2-text ab2-btn-background"   style={{"padding": "0px"}}>
              1st Bet<span>0.97</span>
              <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>  </div>:  
              <div class="col-12 ab2-text ab2-btn-background"  onClick={async()=>{
               
               OpenBetBoard({
                  Value:"0.97",
                  Type:"ANDAR", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"1st Bet Andar",
                  SelectionId:CircleCards.Data.t2[0].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               } style={{"padding": "0px"}}>
              1st Bet<span>0.97</span>
              </div>
              }
          
         
           <div style={{"color": "red"}}>0 </div>
        </div>
        <div class="col-3 col-sm-3 col-md-3">
              {CircleCards.Data&&(CircleCards.Data.t2[1].gstatus==="0")?
              <div class="col-12 ab2-text ab2-btn-background"   style={{"padding": "0px"}}>
              2d Bet<span>0.97</span>
              <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>  </div>:  
              <div class="col-12 ab2-text ab2-btn-background"  onClick={async()=>{
               
               OpenBetBoard({
                  Value:"0.97",
                  Type:"ANDAR", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"2nd Bet Andar",
                    SelectionId:CircleCards.Data.t2[1].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               } style={{"padding": "0px"}}>
              2nd Bet<span>0.97</span>
              </div>
              }
          
         
           <div style={{"color": "red"}}>0 </div>
        </div>
     
        <div class="col-3 col-sm-3 col-md-3">
              {CircleCards.Data&&(CircleCards.Data.t2[2].gstatus==="0")?
              <div class="col-12 ab2-text ab2-btn-background"   style={{"padding": "0px"}}>
              3rd Bet<span>0.97</span>
              <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>  </div>:  
              <div class="col-12 ab2-text ab2-btn-background"  onClick={async()=>{
               
               OpenBetBoard({
                  Value:"0.97",
                  Type:"ANDAR", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"3rd Bet Andar",
                    SelectionId:CircleCards.Data.t2[2].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               } style={{"padding": "0px"}}>
              3rd Bet<span>0.97</span>
              </div>
              }
          
         
           <div style={{"color": "red"}}>0 </div>
        </div>
       
        <div style={{"text-align": "center"}} class="col-12 col-sm-2 col-md-2">
           <div class="ab2-text"><strong  style={{"color": "rgb(0, 0, 0)", "font-weight": "700"}}>Bahar</strong></div>
        </div>
             <div class="col-3 col-sm-3 col-md-3">
              {CircleCards.Data&&(CircleCards.Data.t2[3].gstatus==="0")?
              <div class="col-12 ab2-text ab2-btn-background"   style={{"padding": "0px"}}>
              1st Bet<span>0.97</span>
              <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>  </div>:  
              <div class="col-12 ab2-text ab2-btn-background"  onClick={async()=>{
               
               OpenBetBoard({
                  Value:"0.97",
                  Type:"BAHAR", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"1st Bet Bahar",
                    SelectionId:CircleCards.Data.t2[3].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               } style={{"padding": "0px"}}>
              1st Bet<span>0.97</span>
              </div>
              }
          
         
           <div style={{"color": "red"}}>0 </div>
        </div>
      
        <div class="col-3 col-sm-3 col-md-3">
              {CircleCards.Data&&(CircleCards.Data.t2[4].gstatus==="0")?
              <div class="col-12 ab2-text ab2-btn-background"   style={{"padding": "0px"}}>
              2nd Bet<span>0.97</span>
              <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>  </div>:  
              <div class="col-12 ab2-text ab2-btn-background"  onClick={async()=>{
               
                 
               OpenBetBoard({
                  Value:"0.97",
                  Type:"BAHAR", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"2nd Bet Bahar",
                    SelectionId:CircleCards.Data.t2[4].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               } style={{"padding": "0px"}}>
              2nd Bet<span>0.97</span>
              </div>
              }
          
         
           <div style={{"color": "red"}}>0 </div>
        </div>
          
        <div class="col-3 col-sm-3 col-md-3">
              {CircleCards.Data&&(CircleCards.Data.t2[5].gstatus==="0")?
              <div class="col-12 ab2-text ab2-btn-background"   style={{"padding": "0px"}}>
              3rd Bet<span>0.97</span>
              <div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>  </div>:  
              <div class="col-12 ab2-text ab2-btn-background"  onClick={async()=>{
               
                 
               OpenBetBoard({
                  Value:"0.97",
                  Type:"BAHAR", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"3rd Bet Bahar",
                    SelectionId:CircleCards.Data.t2[5].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               } style={{"padding": "0px"}}>
              3rd Bet<span>0.97</span>
              </div>
              }
          
         
           <div style={{"color": "red"}}>0 </div>
        </div>
        
     
        
        </div>
       <div class="row mr-left-right lucky7-main">
        <div class="col-12 col-md-6 lucky7-main-bhav" style={{"display": "flex"}}>
           <div class="col-6 col-md-6 ">
              <span class="col-12 col-md-12" style={{"color": "rgb(0, 0, 0)","font-weight": "700"}}>Odd</span>
             
                 {CircleCards.Data&&(CircleCards.Data.t2[23].gstatus==="0")? <div class="col-12 col-md-12 lucky7-bhav"  style={{"padding": "0px"}}>
                 <span><div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
                    0.83
                 </span>
              </div>:
               <div class="col-12 col-md-12 lucky7-bhav" onClick={async()=>{
               
               OpenBetBoard({
                  Value:"0.83",
                  Type:"ODD", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"Odd",
                    SelectionId:CircleCards.Data.t2[23].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               }
                style={{"padding": "0px"}}>
                 <span> 
                    0.83
                 </span>
              </div>
            }
       
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-6 col-md-6 ">
              <span class="col-12 col-md-12" style={{"color": "rgb(0, 0, 0)","font-weight": "700"}}>Even</span>
            
              {CircleCards.Data&&(CircleCards.Data.t2[24].gstatus==="0")? <div class="col-12 col-md-12 lucky7-bhav"  style={{"padding": "0px"}}>
                 <span><div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
                 1.12
                 </span>
              </div>:
               <div class="col-12 col-md-12 lucky7-bhav" onClick={async()=>{
               
                  OpenBetBoard({
                     Value:"1.2",
                     Type:"EVEN", 
                     Round:CircleCards.Data.round_id, 
                     Game:"abj", 
                     U_ID:authUser.ID, 
                     Id:"Even",
                       SelectionId:CircleCards.Data.t2[24].sid,
                      Size:0,
                       Sport:"Casino",
                   GType:"Andar Bahar 2" 
                    })
                 }
               }
                style={{"padding": "0px"}}>
                 <span> 
                 1.12
                 </span>
              </div>
            } 
              <div style={{"color": "red"}}>0 </div>
           </div>
        </div>
        <div class="col-12 col-md-6 lucky7-main-bhav" style={{"display": "flex"}}>
           <div class="col-3 col-md-3 ">
              <span class="col-12 col-md-12 "><img class="tpatti-img-height" src="https://urexch.in/cards/ace-of-spades.png"/></span>
     
                 {CircleCards.Data&&(CircleCards.Data.t2[19].gstatus==="0")?    
                      <div class="col-12 col-md-12 lucky7-bhav"  style={{"padding": "0px"}}>
                 <span><div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
                    <div class="lucky7-bhav">2.83</div>
                 </span>
              </div>:     <div class="col-12 col-md-12 lucky7-bhav" onClick={async()=>{
               
               OpenBetBoard({
                  Value:"2.83",
                  Type:"HH", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"HH",
                    SelectionId:CircleCards.Data.t2[19].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               } style={{"padding": "0px"}}>
                 <span> 
                    <div class="lucky7-bhav">2.83</div>
                 </span>
              </div>}
       
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-3 col-md-3  ">
              <span class="col-12 col-md-12"><img class="tpatti-img-height" src="https://urexch.in/cards/clover-ace.png"/></span>
            
            
              {CircleCards.Data&&(CircleCards.Data.t2[20].gstatus==="0")?    
                      <div class="col-12 col-md-12 lucky7-bhav"  style={{"padding": "0px"}}>
                 <span><div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
                    <div class="lucky7-bhav">2.83</div>
                 </span>
              </div>:     <div class="col-12 col-md-12 lucky7-bhav" onClick={async()=>{
               
               OpenBetBoard({
                  Value:"2.83",
                  Type:"CC", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"CC",
                    SelectionId:CircleCards.Data.t2[20].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               } style={{"padding": "0px"}}>
                 <span> 
                    <div class="lucky7-bhav">2.83</div>
                 </span>
              </div>}
            
             
              <div style={{"color": "red"}}>0 </div>
           </div>





           <div class="col-3 col-md-3 ">
              <span class="col-12 col-md-12"><img class="tpatti-img-height" src="https://urexch.in/cards/heart.png"/></span>
       
              {CircleCards.Data&&(CircleCards.Data.t2[21].gstatus==="0")?    
                      <div class="col-12 col-md-12 lucky7-bhav"  style={{"padding": "0px"}}>
                 <span><div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
                    <div class="lucky7-bhav">2.83</div>
                 </span>
              </div>:     <div class="col-12 col-md-12 lucky7-bhav" onClick={async()=>{
            OpenBetBoard({
               Value:"2.83",
               Type:"DD", 
               Round:CircleCards.Data.round_id, 
               Game:"abj", 
               U_ID:authUser.ID, 
               Id:"DD",
                 SelectionId:CircleCards.Data.t2[21].sid,
                Size:0,
                 Sport:"Casino",
                   GType:"Andar Bahar 2" 
              })
           }
               } style={{"padding": "0px"}}>
                 <span> 
                    <div class="lucky7-bhav">2.83</div>
                 </span>
              </div>}
            
            
       
        
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-3 col-md-3  ">
              <span class="col-12 col-md-12"><img class="tpatti-img-height" src="https://urexch.in/cards/diamond.png"/></span>
    
    
              {CircleCards.Data&&(CircleCards.Data.t2[22].gstatus==="0")?    
                      <div class="col-12 col-md-12 lucky7-bhav"  style={{"padding": "0px"}}>
                 <span><div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>
                    <div class="lucky7-bhav">2.83</div>
                 </span>
              </div>:     <div class="col-12 col-md-12 lucky7-bhav" onClick={async()=>{
           OpenBetBoard({
            Value:"2.83",
            Type:"SS", 
            Round:CircleCards.Data.round_id, 
            Game:"abj", 
            U_ID:authUser.ID, 
            Id:"SS",
              SelectionId:CircleCards.Data.t2[22].sid,
             Size:0,
              Sport:"Casino",
                   GType:"Andar Bahar 2" 
           })
        }
               } style={{"padding": "0px"}}>
                 <span> 
                    <div class="lucky7-bhav">2.83</div>
                 </span>
              </div>}
            
     
              <div style={{"color": "red"}}>0 </div>
           </div>
        </div>
     </div>









     <div class="row mr-left-right lucky7-main">
        <div class="col-12 col-md-12 lucky7-main-bhav"><span style={{"color": "rgb(0, 0, 0)", "font-weight": "700"}}>11.00</span></div>
        <div class="row mr-left-right" style={{"width": "100%"}}>
           <div class="col-1 lucky7-main-bhav-card"  style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[6].gstatus==="0")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img  onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"1", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"A",
                    SelectionId:CircleCards.Data.t2[6].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               }src="https://urexch.in/cards/1.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[7].gstatus==="0")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img  onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"2", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"2",
                    SelectionId:CircleCards.Data.t2[7].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               }src="https://urexch.in/cards/2.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[8].gstatus==="0")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img  onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"3", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"3",
                    SelectionId:CircleCards.Data.t2[8].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               }src="https://urexch.in/cards/3.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[9].gstatus==="0")?<div class="susb-bhav"><img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"4", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"4",
                    SelectionId:CircleCards.Data.t2[9].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               }src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img src="https://urexch.in/cards/4.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[10].gstatus==="0")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"5", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"5",
                    SelectionId:CircleCards.Data.t2[10].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               }src="https://urexch.in/cards/5.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[11].gstatus==="0")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"6", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"6",
                    SelectionId:CircleCards.Data.t2[11].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               }src="https://urexch.in/cards/6.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[12].gstatus==="0")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"7", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"7",
                    SelectionId:CircleCards.Data.t2[12].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               }src="https://urexch.in/cards/7.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[13].gstatus==="0")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"8", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"8",
                    SelectionId:CircleCards.Data.t2[13].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               } src="https://urexch.in/cards/8.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[14].gstatus==="0")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"9", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"9",
                    SelectionId:CircleCards.Data.t2[14].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               }src="https://urexch.in/cards/9.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[15].gstatus==="0")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"10", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"10",
                    SelectionId:CircleCards.Data.t2[15].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               }src="https://urexch.in/cards/10.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[16].gstatus==="0")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"11", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"J",
                    SelectionId:CircleCards.Data.t2[16].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               }src="https://urexch.in/cards/11.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[17].gstatus==="0")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"12", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"Q",
                    SelectionId:CircleCards.Data.t2[17].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               }src="https://urexch.in/cards/12.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
           <div class="col-1 lucky7-main-bhav-card" style={{"padding": "0px"}}>
              <span class="col-12" style={{"padding": "0px"}}>
              {CircleCards.Data&&(CircleCards.Data.t2[18].gstatus==="0")?<div class="susb-bhav"><img src="https://urexch.in/padlock.png"/></div>:null}
       
                 <img onClick={async()=>{
               
               OpenBetBoard({
                  Value:"11",
                  Type:"13", 
                  Round:CircleCards.Data.round_id, 
                  Game:"abj", 
                  U_ID:authUser.ID, 
                  Id:"K",
                    SelectionId:CircleCards.Data.t2[18].sid,
                   Size:0,
                    Sport:"Casino",
                   GType:"Andar Bahar 2" 
                 })
              }
               } src="https://urexch.in/cards/13.jpg" style={{"height": "60px"}}/>
              </span>
              <div style={{"color": "red"}}>0 </div>
           </div>
        </div>
     </div>
     <div style={{"margin-left": "0px", "margin-right": "0px"}} class="row">
        <div class="match-box">
           <div class="mod-header">
              <div class="select-tv-ico"><span>Last Result</span><span style={{"float": "right"}}><a style={{"color": "rgb(255, 255, 255)"}} href="/account/ac-statement">View All</a></span></div>
           </div>
        </div>
        <div class="Result" style={{"color": "white", "background-color": "rgb(255, 255, 255)", "text-align": "right", "width": "100%"}}>
            <span class="game-result playera">A</span>
            <span class="game-result playera">A</span>
            <span class="game-result playerb">B</span>
            <span class="game-result playera">A</span>
            <span class="game-result playerb">B</span>
            <span class="game-result playera">A</span>
            <span class="game-result playerb">B</span>
            <span class="game-result playerb">B</span>
            <span class="game-result playerb">B</span>
            <span class="game-result playerb">B</span>
            </div>
     </div>
     </div>
    
  </>
 
    )

};

export default Andar_Bahar_2;
