  
import React from "react"; 
import { Link } from "react-router-dom";
import Header from "./Header";
import MainWrapper from "./MainWrapper";
 
import NavBar from "./NavBar";

function Sections() {
   return [
    Header(),NavBar() ,MainWrapper() ];
}

export default Sections;
