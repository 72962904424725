import {
  BrowserRouter as Router,
  Switch,
  Route, 
} from "react-router-dom"; 
import React from 'react';  
import "./assets/vendors/style";
import "./assets/vendors/script";
import {AppStateProvider } from './AppState';
import App from './App';

const NextApp = () =>

<AppStateProvider >
<Router>
<Switch> 
 <Route path="/" component={App}/> 

</Switch>
</Router>
</AppStateProvider >;

export default NextApp;