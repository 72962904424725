  
import {React,useContext,useEffect} from "react";  
import { AppStateContext } from '../../../../AppState';  

import { getEvents } from '../../../../util/rex';
function Sidebar() {

  const { state, updateState } = useContext(AppStateContext);
 
  useEffect(() => {
    const fetchData = async () => {
      
      await getEvents().then((events)=>{


        updateState({E: events});    
          })
     
    
    };

    fetchData();
  }, []); 
  
   return (
    <aside id="mySidenav" className="sidenav  h-auto left-side">
    <div className="side-inner">
      <div className="tab-content">
        <div id="live" className="  ">
          <nav id="menu" className="shadow corner all">
            <div className="dropdown"><a className="dropdown-toggle" data-toggle="collapse"  data-target=".collapse" >
              <img  src="./assets/images/cricket.png" className="icon-sport"/>Cricket
                  <span className="match_count"><i
                    className="fas fa-chevron-down"></i></span>
                    </a>
              <div className="collapse">
               
       {state.E && state.E.length>0 ? state.E.map((item, index) => (
                                  
    <div style={{"padding": "0px 15px", "border-bottom": "1px solid rgb(221, 221, 221)"}}><a className="event-name"
    href={"/dashboard/events/"+item.Event_Id}> {item.Event_Name} </a></div>
    )) : null}
    
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </aside>
  );
}

export default Sidebar;
