import {React,useState} from 'react';  
import {UpdatePassword } from '../../../App/Signin/Auth';  

 const ChangePassword=()=> {
 
  const [IsLoading, setIsLoading] = useState(false);
  const [Message, setMessage] = useState('');
  const [AuthForm, setAuthForm] = useState(true);
 

    const CheckForm = () => { 
     

if(document.getElementById("NewPass").value.length>5){
  if(document.getElementById("ConfPass").value.length>=6){
    if(document.getElementById("NewPass").value!=document.getElementById("ConfPass").value){
    setAuthForm(false);
 setMessage("Password did not matched");
 
    } else{
      setAuthForm(true);

      setMessage("");

    }


  }else{
    
setAuthForm(false);
setMessage( "Minimum Six characters");
  }
     


}
  else{
        setAuthForm(false);
        setMessage("Minimum five digits and contain character");
        
    }
        

    return
      
     

    }



      const handleChange = (e) => {
        
 setMessage("");
          CheckForm();
      };
      const handleSubmit = async(e) => {
        e.preventDefault();

        if(AuthForm==true){
         
       const  formData =  {
        OldPass: document.getElementById("OldPass").value,
       NewPass: document.getElementById("NewPass").value,
       ConfPass: document.getElementById("ConfPass").value}

      await  UpdatePassword(formData).then((resp)=>{
 
        setMessage(resp.message); 


      }) ;
     }
       
    }


   return (
    <div className="main-content">
   <div className="main-inner">
      <section className="match-content">
         <div className="table_tittle" style={{"display": "flex", "justify-content": "space-between"}}>
            Change Password
            <div style={{"color": "red"}}> {Message}</div>
         </div>
         <div className="col-md-12 "></div>
         <div className="clearfix data-background">
         <form className="m-0 " onSubmit={handleSubmit}>
    
            <div className="col-md-12 form-horizontal row form-label-left input_mask">
               <div className="col-md-4 col-xs-6"><label for="firstname" className="pwdlable">Old Password <span className="required">*</span></label>
               <input type="password" onChange={handleChange} name="OldPass" id="OldPass" required maxlength="100" size="50" className="form-control" placeholder="Old Password"/></div>
               <div className="col-md-4 col-xs-6"><label for="firstname" className="pwdlable">New Password <span className="required">*</span></label>
               <input type="password" onChange={handleChange}  name="NewPass" id="NewPass"  className="form-control" placeholder="New Password"/></div>
               <div className="col-md-4 col-xs-6"><label for="firstname" required className="pwdlable">Retype New Password <span className="required">*</span></label>
               <input type="password" onChange={handleChange}  name="ConfPass"id="ConfPass"  required className="form-control" placeholder="Retype Password"/></div>
               <div className="col-md-4 btn-bethistory-filter col-xs-12">
                  <div className="col-md-12 col-xs-12 ">
                    <button type="reset" className="btn btn-primary resetPassword-button">Reset</button>
                    <button type="submit" className="btn btn-primary resetPassword-button">Submit</button>
                    </div>
               </div>
            </div> 
  </form>
         </div>
         <div id="divLoading"> </div>
      </section>
   </div>
</div>
  );
}

export default ChangePassword;
