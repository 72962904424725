import React, { useContext,useState } from "react";
import { Route, Switch } from "react-router-dom"; 
 


const Aviator = ({ match }) => {
alert("Aviator");
    return (
     <>Comming Soon..</>
    )

};

export default Aviator;
