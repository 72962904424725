import {React,useState,useEffect,useContext} from 'react';
import { useParams  } from 'react-router-dom'; 
import { AppStateContext }   from '../AppState';
import { getBets } from '../util/rex';
 

const MyBets = (props) => { 
  const [Bets, setBets] = useState({}); 

  const { state, updateState } = useContext(AppStateContext);
    
  const  {eid}  =   useParams()
  
  useEffect(() => {
    const fetchData = async () => {
     
      setBets( await getBets(eid));  

    };

    fetchData();
  }, [updateState]); 
  return (
    <table  className="table table-bordered table-hover">
      <thead>
      <tr className="heading_user_table">
                    
        <td>Team</td>
                        <td>Mode</td>
                        <td>Run</td>
                        <td>Rate</td>
                        <td>Amount</td>
        </tr>
      </thead>
      <tbody>
        
        {Bets&&Bets.length>0?Bets.map((item) => (
 
<tr key={item.Player_Name} className="heading_user_table">


{
props.type=="All"?<>
<td>{item.Player_Name}</td>
    <td>{item.Bet_Type}</td>
       <td>{ item.Game_Type == 'Session'?item.Bet_Value:item.Volume}</td>
       <td>{ item.Game_Type == 'Session'?item.Volume:item.Bet_Value.toFixed(2)}</td> 
    

   <td>{item.Stake}</td>
</>
 :props.type== item.Game_Type?<>

 <td>{item.Player_Name}</td>
     <td>{item.Bet_Type}</td>
        <td>{ item.Game_Type == 'Session'?item.Bet_Value:item.Volume}</td>
        <td>{ item.Game_Type == 'Session'?item.Volume:item.Bet_Value.toFixed(2)}</td> 
     
 
    <td>{item.Stake}</td>
 </>:null
 
 
 }

   
 </tr> 


        )):null}
      </tbody>
    </table>
  );
};

export default MyBets;