import React  from 'react'; 
 
import {  message } from 'antd';

import axios from '../../util/Api'   
   
 
export const getUser = async() => {
  
     try { 
          const response = await axios.post('/info' );

   const data = await response.data; 
    
   if (data.success===true){
 
    localStorage.setItem("authUser", JSON.stringify( data.user));   
    localStorage.setItem("Login", true);  
  
      
  return  {login:true,authUser: data.user }
 
   }else{
    
      localStorage.removeItem("token"); 
      localStorage.removeItem("authUser");  
      localStorage.removeItem("Login");  

      
      return  {login:false } 
   } 
     
  }
  catch (e) {  
       
     message.error(e.message);  
     return  {login:false } 
  } 
};

export const getAuth = async (Credentials) => {
  try { 
    
    const response = await axios.post('/signin',Credentials);
   const data = await response.data; 
   if (data.success===true){

    localStorage.setItem("token",  data.token );  
    localStorage.setItem("Login", true);  
  
    localStorage.setItem("showAgrementModal", true);  
  
   window.location='/';
   //message.success(data.message); 
   //alert(data.message);
 
   }else{
    localStorage.removeItem("token"); 
      localStorage.removeItem("authUser");  
      localStorage.removeItem("Login");  
      //alert(data.message);
 
  //  message.error(data.message); 

   } 
     return   await data ;
   
  }
  catch (e) {  
      localStorage.removeItem("token"); 
      localStorage.removeItem("authUser");  
      localStorage.removeItem("Login");  
     // alert(e.message);
   //  message.error(e.message);  
  }
}
 



export const UpdatePassword = async (Credentials) => {
  try { 
    
    const response = await axios.post('/update-pass',Credentials);
   const data = await response.data; 
   if (data.success===true){
 
    localStorage.removeItem("token"); 
    localStorage.removeItem("authUser");  
    localStorage.removeItem("Login");  
 
    window.location='/';
 
   }else{ 
    
  return   await data ;
   
   } 
   
  }
  catch (e) {  
     
     message.error(e.message);  
  }
}
